import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { OrderLineSearchService } from '@app/order/core/services/order-line-search.service';
import { OrderSearchService } from '@app/order/core/services/order-search.service';
import { ShipmentSearchService } from '@app/shipment/core/services/shipment-search.service';
import { CompanyService } from '@app/company/core/services/company.service';
import { UserService } from '@app/user/core/services/user.service';

@Component({
  selector: 'tc-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit, OnDestroy {
  @Input()
  companyId!: string;

  public control = new FormControl(null);
  public suggestions$!: Observable<any>;
  public searching$ = new Subject<boolean>();
  private destroyed$ = new Subject<void>();

  public get length(): number {
    return this.control.value && this.control.value.length;
  }

  constructor(
    private companyService: CompanyService,
    private orderSearchService: OrderSearchService,
    private orderLineSearchService: OrderLineSearchService,
    private userService: UserService,
    private shipmentSearchService: ShipmentSearchService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.suggestions$ = this.control.valueChanges.pipe(
      filter((value): value is string => {
        return typeof value === 'string';
      }),
      filter(value => {
        return value.length > 2;
      }),
      tap(() => {
        this.searching$.next(true);
      }),
      debounceTime(300),
      map(query => {
        return {
          query,
          offset: 0,
          limit: 10,
        };
      }),
      switchMap(query => {
        return forkJoin(
          this.orderSearchService.suggest$(query).pipe(
            map(response => {
              return response.data;
            }),
            catchError(err => {
              return of(null);
            }),
          ),
          this.orderLineSearchService.suggest$(query).pipe(
            map(response => {
              return response.data;
            }),
            catchError(err => {
              return of(null);
            }),
          ),
          this.userService.suggest$(query).pipe(
            map(response => {
              return response.data;
            }),
            catchError(err => {
              return of(null);
            }),
          ),
          this.companyService.query$(query).pipe(
            map(response => {
              return response.data;
            }),
            catchError(err => {
              return of(null);
            }),
          ),
          this.shipmentSearchService
            .query$({
              query: query.query,
              filters: {},
              limit: query.limit,
              offset: query.offset,
            })
            .pipe(
              map(response => {
                return response.data;
              }),
              catchError(err => {
                return of(null);
              }),
            ),
        ).pipe(
          map(([orders, orderLines, users, companies, shipments]) => {
            return {
              orders,
              orderLines,
              users,
              companies,
              shipments,
            };
          }),
        );
      }),
      tap(() => {
        this.searching$.next(false);
      }),
      takeUntil(this.destroyed$),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public onSelect(event: MatAutocompleteSelectedEvent): void {
    this.control.reset();
    const value = event.option.value as string[];
    this.router.navigate(value);
  }
}
