import { Injectable } from '@angular/core';
import { EnvService } from '@app/env.service';
import { environment } from '@environments/environment';

@Injectable()
export class API {
  constructor(private envService: EnvService) {}

  public get MAIN(): string {
    return `${this.envService.apiUrl}/v2`;
  }

  public SHIPMENT(): string {
    return `${this.MAIN}/shipment`;
  }

  public SHIPMENT_WS(): string {
    return `${this.SHIPMENT()}/ws`;
  }

  public SHIPMENT_CREATE(): string {
    return `${this.SHIPMENT()}/despatch-advice`;
  }

  public SHIPMENT_SEARCH_LOCATION(): string {
    return `${this.SHIPMENT()}/departure/search`;
  }

  public SHIPMENT_RESEND(id: string): string {
    return `${this.SHIPMENT()}/${id}/resend`;
  }

  public SHIPMENT_REJECT(id: string): string {
    return `${this.SHIPMENT()}/${id}/reject`;
  }

  public SHIPMENT_APPROVE(id: string): string {
    return `${this.SHIPMENT()}/${id}/approve`;
  }

  public SHIPMENT_DOCUMENT(id: string): string {
    return `${this.SHIPMENT()}/${id}/document`;
  }

  public SHIPMENT_ID(id: string): string {
    return `${this.SHIPMENT()}/${id}`;
  }

  public SHIPMENT_SEARCH_QUERY(): string {
    return `${this.SHIPMENT()}/search`;
  }

  public SHIPMENT_WEBHOOK_CONNECTOR(): string {
    return `${this.MAIN}/shipment-webhook-connector`;
  }

  public SHIPMENT_WEBHOOK_CONNECTOR_SETTINGS(id: string): string {
    return `${this.SHIPMENT_WEBHOOK_CONNECTOR()}/company/${id}/settings`;
  }

  public SCI_CONNECTOR(): string {
    return `${this.MAIN}/sci-connector`;
  }

  public SCI_CONNECTOR_SETTINGS(id: string): string {
    return `${this.SCI_CONNECTOR()}/company/${id}/settings`;
  }

  public SCI_CONNECTOR_SETTINGS_URL(id: string): string {
    return `${this.SCI_CONNECTOR_SETTINGS(id)}/url`;
  }

  public SCI_CONNECTOR_SETTINGS_CREDENTIALS(id: string, supplierCompanyId: string): string {
    return `${this.SCI_CONNECTOR_SETTINGS(id)}/${supplierCompanyId}/credentials`;
  }

  public ACTIVITY(): string {
    return `${this.MAIN}/activity`;
  }

  public ACTIVITY_WS(): string {
    return `${this.ACTIVITY()}/ws`;
  }

  public ACTIVITY_SEARCH(): string {
    return `${this.ACTIVITY()}/search`;
  }

  public TOKEN_REFRESH(): string {
    return `${this.AUTHENTICATION()}/refresh`;
  }

  public LOGIN(): string {
    return `${this.AUTHENTICATION()}/login`;
  }

  public VALIDATE(): string {
    return `${this.AUTHENTICATION()}/validate`;
  }

  public VALIDATE_LINK(): string {
    return `${this.AUTHENTICATION()}/validate_invite_link`;
  }

  public LOGOUT(): string {
    return `${this.AUTHENTICATION()}/logout`;
  }

  public GENERATE_2FA_SECRET(): string {
    return `${this.AUTHENTICATION()}/2fa/generate`;
  }

  public ENABLE_2FA(): string {
    return `${this.AUTHENTICATION()}/2fa/enable`;
  }

  public DISABLE_2FA(): string {
    return `${this.AUTHENTICATION()}/2fa/disable/user`;
  }

  public VERIFY_2FA_CODE(): string {
    return `${this.AUTHENTICATION()}/2fa/verify`;
  }

  public VERIFY_RECOVERY_2FA(): string {
    return `${this.AUTHENTICATION()}/2fa/recovery/verify`;
  }

  public DISABLE_RECOVERY_2FA(): string {
    return `${this.AUTHENTICATION()}/2fa/recovery/disable`;
  }

  public UPSERT_SSO_IDENTITY(): string {
    return `${this.AUTHENTICATION()}/sso/identity`;
  }

  public UPSERT_SSO_USER(): string {
    return `${this.USER()}/sso/user`;
  }

  public AUTHENTICATION(): string {
    return `${this.MAIN}/authentication`;
  }

  public FORECAST_LINE_SEARCH(): string {
    return `${this.MAIN}/forecast/line/search`;
  }

  public COMPANY_SEARCH(): string {
    return `${this.COMPANY()}/search`;
  }

  public COMPANY_SEARCH_ID(id: string): string {
    return `${this.COMPANY()}/search/${id}`;
  }

  public PASSWORD(): string {
    return `${this.AUTHENTICATION()}/password`;
  }

  public PASSWORD_RESET_REQUEST(): string {
    return `${this.PASSWORD()}/request_reset`;
  }

  public PASSWORD_RESET(): string {
    return `${this.PASSWORD()}/reset`;
  }

  public ORDER_WEBHOOK(): string {
    return `${this.MAIN}/order-webhook-connector`;
  }

  public ORDER_WEBHOOK_SETTINGS(id: string): string {
    return `${this.ORDER_WEBHOOK()}/company/${id}/settings`;
  }

  public ORDER_WEBHOOK_ORDER_EVENTS(id: string): string {
    return `${this.ORDER_WEBHOOK_SETTINGS(id)}/orderEvents`;
  }

  public ORDER_WEBHOOK_DOCUMENTS_EVENTS(id: string): string {
    return `${this.ORDER_WEBHOOK_SETTINGS(id)}/orderDocumentsEvents`;
  }
  public WEBHOOK_DOCUMENTS_CERTIFICATE(id: string): string {
    return `${this.ORDER_WEBHOOK_DOCUMENTS_EVENTS(id)}/oAuthCertificate`;
  }

  public WEBHOOK_EVENTS_CERTIFICATE(id: string): string {
    return `${this.ORDER_WEBHOOK_ORDER_EVENTS(id)}/oAuthCertificate`;
  }

  public COMPANY(): string {
    return `${this.MAIN}/company`;
  }

  public COMPANY_CREATE(): string {
    return `${this.COMPANY()}/`;
  }

  public COMPANY_AUTHORIZE_AS_SUPPLIER(id: string): string {
    return `${this.COMPANY()}/${id}/authorize/supplier`;
  }

  public COMPANY_ID(id: string): string {
    return `${this.COMPANY()}/${id}`;
  }

  public COMPANY_PATCH_NAME(id: string): string {
    return `${this.COMPANY_ID(id)}/name`;
  }

  public COMPANY_ENFORCE_2FA(id: string): string {
    return `${this.COMPANY()}/${id}/2fa/enforce`;
  }

  public COMPANY_UNRESTRICT_2FA(id: string): string {
    return `${this.COMPANY()}/${id}/2fa/unrestrict`;
  }

  public COMPANY_WS(): string {
    return `${this.COMPANY()}/ws`;
  }

  public COMPANY_ACCOUNT(companyId: string): string {
    return `${this.COMPANY_ID(companyId)}/account`;
  }

  public COMPANY_ACCOUNT_CODE(companyId: string, accountCode: string): string {
    return `${this.COMPANY_ACCOUNT(companyId)}/${accountCode}`;
  }

  public COMPANY_ACCOUNT_CREATE(companyId: string): string {
    return `${this.COMPANY_ACCOUNT(companyId)}`;
  }

  public COMPANY_CONNECTION(companyId: string): string {
    return `${this.COMPANY_ID(companyId)}/connection`;
  }

  public COMPANY_CONNECTION_ID(companyId: string, otherCompanyId: string): string {
    return `${this.COMPANY_CONNECTION(companyId)}/${otherCompanyId}`;
  }

  public COMPANY_CONNECTION_ID_REQUEST(companyId: string, otherCompanyId: string): string {
    return `${this.COMPANY_CONNECTION_ID(companyId, otherCompanyId)}/request`;
  }

  public COMPANY_CONNECTION_ID_ACCEPT(companyId: string, otherCompanyId: string): string {
    return `${this.COMPANY_CONNECTION_ID(companyId, otherCompanyId)}/accept`;
  }

  public COMPANY_CONNECTION_SEARCH_QUERY(companyId: string): string {
    return `${this.COMPANY_CONNECTION(companyId)}/search`;
  }

  public CONVERSATION(): string {
    return `${this.MAIN}/conversation`;
  }

  public CONVERSATION_SEND_MESSAGE(): string {
    return `${this.CONVERSATION()}/send_message`;
  }

  public OBJECT_STORAGE(): string {
    return `${this.MAIN}/object-storage`;
  }

  public OBJECT_STORAGE_DOCUMENT_UPLOAD(): string {
    return `${this.OBJECT_STORAGE()}/document/upload`;
  }

  public OBJECT_STORAGE_IMAGE(id: string): string {
    return `${this.OBJECT_STORAGE()}/image/${id}/metadata`;
  }

  public OBJECT_STORAGE_DOCUMENT(id: string): string {
    return `${this.OBJECT_STORAGE()}/document/${id}/metadata`;
  }

  public OBJECT_STORAGE_DOWNLOAD(fileQuery: string): string {
    const [fileId, params] = fileQuery.split('?');

    return `${this.OBJECT_STORAGE()}/image/${fileId}/metadata?${params}`;
  }

  public OBJECT_STORAGE_UPLOAD_IMAGE(): string {
    return `${this.OBJECT_STORAGE()}/image`;
  }

  public OBJECT_DOCUMENT_STORAGE(): string {
    return `${this.MAIN}/object-storage/document`;
  }

  public OBJECT_DOCUMENT_DIRECT_DOWNLOAD(id: string): string {
    return `${this.OBJECT_DOCUMENT_STORAGE()}/${id}`;
  }

  public OBJECT_DOCUMENT_STORAGE_UPLOAD(): string {
    return this.OBJECT_DOCUMENT_STORAGE();
  }

  public OBJECT_DOCUMENT_STORAGE_DOWNLOAD(fileQuery: string): string {
    return `${this.OBJECT_DOCUMENT_STORAGE()}/${fileQuery}/metadata`;
  }

  public ORDER_LINE_SEARCH(): string {
    return `${this.MAIN}/order-line-search`;
  }

  public ORDER_LINE_SEARCH_ID(orderId: string): string {
    return `${this.ORDER_LINE_SEARCH()}/${orderId}`;
  }

  public ORDER_LINE_SEARCH_QUERY(): string {
    return `${this.ORDER_LINE_SEARCH()}/search`;
  }

  public ORDER_LINE_SEARCH_SUGGEST(): string {
    return `${this.ORDER_LINE_SEARCH()}/suggest`;
  }

  public ORDER_LINE_SEARCH_METRICS(): string {
    return `${this.ORDER_LINE_SEARCH()}/metrics`;
  }

  public ORDER_LINE_SEARCH_METRICS_PROCESS_STATUS(): string {
    return `${this.ORDER_LINE_SEARCH_METRICS()}/processStatusCount`;
  }

  public ORDER_SEARCH(): string {
    return `${this.MAIN}/order-search`;
  }

  public ORDER_SEARCH_ID(orderId: string): string {
    return `${this.ORDER_SEARCH()}/${orderId}`;
  }

  public ORDER_SEARCH_QUERY(): string {
    return `${this.ORDER_SEARCH()}/search`;
  }

  public ORDER_CUSTOM_LABELS(companyId: string): string {
    return `${this.ORDER_SEARCH()}/customLabels/${companyId}`;
  }

  public ORDER_SEARCH_SUGGEST(): string {
    return `${this.ORDER_SEARCH()}/suggest`;
  }

  public ORDER_DESTINATIONS(): string {
    return `${this.ORDER_SEARCH()}/destinations`;
  }

  public WORKFLOW(): string {
    return `${this.MAIN}/workflow`;
  }

  public WORKFLOW_QUERY(): string {
    return `${this.WORKFLOW()}/search`;
  }

  public WORKFLOW_TASK_CLOSE(): string {
    return `${this.WORKFLOW()}/conversation/close`;
  }

  public WORKFLOW_ORDER_ACKNOWLEDGE_TASKS_CLOSE(): string {
    return `${this.WORKFLOW()}/order/close`;
  }

  public WORKFLOW_CONVERSATION_REASSIGN(): string {
    return `${this.WORKFLOW()}/conversation/reassign`;
  }

  public WORKFLOW_SHIPMENT_REASSIGN(): string {
    return `${this.WORKFLOW()}/shipment/reassign`;
  }

  public WORKFLOW_FORECAST_REASSIGN(): string {
    return `${this.WORKFLOW()}/forecast/reassign`;
  }

  public WORKFLOW_ORDER_REASSIGN(): string {
    return `${this.WORKFLOW()}/order/reassign`;
  }

  public WORKFLOW_SHIPMENT_TASKS_CLOSE(): string {
    return `${this.WORKFLOW()}/shipment/close`;
  }

  public WORKFLOW_FORECAST_ACKNOWLEDGE_TASK_CLOSE(): string {
    return `${this.WORKFLOW()}/forecast/close`;
  }

  public WORKFLOW_WS(): string {
    return `${this.WORKFLOW()}/ws`;
  }

  public WORKFLOW_COMPANY_SETTINGS(companyId: string): string {
    return `${this.WORKFLOW()}/company/${companyId}/settings`;
  }

  public WORKFLOW_COMPANY_SETTINGS_OVERDUE(companyId: string): string {
    return `${this.WORKFLOW_COMPANY_SETTINGS(companyId)}/overdue`;
  }

  public USER_SEARCH(): string {
    return `${this.USER()}/search`;
  }

  public USER_SEARCH_ID(userId: string): string {
    return `${this.USER_SEARCH()}/${userId}`;
  }

  public USER_SEARCH_SUGGEST(): string {
    return `${this.USER()}/suggest`;
  }

  public USER_SEARCH_EMAIL(): string {
    return `${this.USER_SEARCH()}/email`;
  }

  public USER(): string {
    return `${this.MAIN}/user`;
  }

  public USER_CREATE(): string {
    return `${this.USER()}/invite`;
  }

  public USER_REINVITE(userId: string): string {
    return `${this.USER()}/${userId}/reinvite`;
  }

  public USER_UPDATE(userId: string): string {
    return `${this.USER()}/${userId}`;
  }

  public USER_DELETE(userId: string): string {
    return `${this.USER()}/${userId}`;
  }

  public USER_WS(): string {
    return `${this.USER()}/ws`;
  }

  public ORDER(): string {
    return `${this.MAIN}/order`;
  }

  public ORDER_COMPANY_SETTINGS_OVERDUE(companyId: string): string {
    return `${this.ORDER()}/company/${companyId}/settings/overdue`;
  }

  public ORDER_WS(): string {
    return `${this.ORDER()}/ws`;
  }

  public ORDER_DOCUMENT(orderId: string): string {
    return `${this.ORDER()}/${orderId}/document`;
  }

  public ORDER_SUPPLIER_ASSIGN(orderId: string): string {
    return `${this.ORDER()}/${orderId}/supplier/contact/reassign`;
  }

  public ORDER_BUYER_ASSIGN(orderId: string): string {
    return `${this.ORDER()}/${orderId}/buyer/contact/reassign`;
  }

  public ORDER_BUYER_LABELS(orderId: string): string {
    return `${this.ORDER()}/${orderId}/customLabels/buyer`;
  }

  public ORDER_SUPPLIER_LABELS(orderId: string): string {
    return `${this.ORDER()}/${orderId}/customLabels/supplier`;
  }

  public ORDER_ACCEPT(orderId: string): string {
    return `${this.ORDER()}/${orderId}/accept`;
  }

  public ORDER_RESEND_BUYER(orderId: string): string {
    return `${this.ORDER()}/${orderId}/buyer/resend`;
  }

  public ORDER_RESEND_SUPPLIER(orderId: string): string {
    return `${this.ORDER()}/${orderId}/supplier/resend`;
  }

  public ORDER_REJECT(orderId: string): string {
    return `${this.ORDER()}/${orderId}/reject`;
  }

  public ORDER_REQUESTS_REJECT_AS_BUYER(orderId: string): string {
    return `${this.ORDER()}/${orderId}/requests/buyer/reject`;
  }

  public ORDER_REQUESTS_REJECT_AS_SUPPLIER(orderId: string): string {
    return `${this.ORDER()}/${orderId}/requests/supplier/reject`;
  }

  public ORDER_REQUESTS_APPROVE_AS_BUYER(orderId: string): string {
    return `${this.ORDER()}/${orderId}/requests/buyer/approve`;
  }

  public ORDER_REQUESTS_APPROVE_AS_SUPPLIER(orderId: string): string {
    return `${this.ORDER()}/${orderId}/requests/supplier/approve`;
  }

  public ORDER_PROPOSE(orderId: string): string {
    return `${this.ORDER()}/${orderId}/propose`;
  }

  public ORDER_REOPEN(orderId: string): string {
    return `${this.ORDER()}/${orderId}/reopen`;
  }

  public ORDER_UPDATE_LOGISTIC(orderId: string): string {
    return `${this.ORDER()}/${orderId}/deliverySchedule`;
  }

  public ORDER_WITH_LINE(orderId: string, linePosition: string): string {
    return `${this.ORDER()}/${orderId}/line/${linePosition}`;
  }

  public ORDER_LINE_BUYER_LABELS(orderId: string, linePosition: string): string {
    return `${this.ORDER_WITH_LINE(orderId, linePosition)}/customLabels/buyer`;
  }

  public ORDER_LINE_SUPPLIER_LABELS(orderId: string, linePosition: string): string {
    return `${this.ORDER_WITH_LINE(orderId, linePosition)}/customLabels/supplier`;
  }

  public ORDER_WITH_LINE_UPDATE_LOGISTIC(orderId: string, linePosition: string): string {
    return `${this.ORDER_WITH_LINE(orderId, linePosition)}/deliverySchedule`;
  }

  public ORDER_ITEM_DETAILS(orderId: string, linePosition: string): string {
    return `${this.ORDER_WITH_LINE(orderId, linePosition)}/item/details`;
  }

  public ORDER_LINE_DOCUMENT(orderId: string, linePosition: string): string {
    return `${this.ORDER_WITH_LINE(orderId, linePosition)}/document`;
  }

  public ORDER_WITH_LINE_ACCEPT(orderId: string, linePosition: string): string {
    return `${this.ORDER_WITH_LINE(orderId, linePosition)}/accept`;
  }

  public ORDER_WITH_LINE_REJECT(orderId: string, linePosition: string): string {
    return `${this.ORDER_WITH_LINE(orderId, linePosition)}/reject`;
  }

  public ORDER_WITH_LINE_PROPOSE(orderId: string, linePosition: string): string {
    return `${this.ORDER_WITH_LINE(orderId, linePosition)}/propose`;
  }

  public ORDER_WITH_LINE_REOPEN(orderId: string, linePosition: string): string {
    return `${this.ORDER_WITH_LINE(orderId, linePosition)}/reopen`;
  }

  public ORDER_WITH_LINE_REOPEN_APPROVE_AS_BUYER(orderId: string, linePosition: string): string {
    return `${this.ORDER_WITH_LINE_REOPEN(orderId, linePosition)}/buyer/approve`;
  }

  public ORDER_WITH_LINE_REOPEN_APPROVE_AS_SUPPLIER(orderId: string, linePosition: string): string {
    return `${this.ORDER_WITH_LINE_REOPEN(orderId, linePosition)}/supplier/approve`;
  }

  public ORDER_WITH_LINE_REOPEN_REJECT_AS_BUYER(orderId: string, linePosition: string): string {
    return `${this.ORDER_WITH_LINE_REOPEN(orderId, linePosition)}/buyer/reject`;
  }

  public ORDER_WITH_LINE_REOPEN_REJECT_AS_SUPPLIER(orderId: string, linePosition: string): string {
    return `${this.ORDER_WITH_LINE_REOPEN(orderId, linePosition)}/supplier/reject`;
  }

  public ORDER_WITH_LINE_PROPOSAL(orderId: string, linePosition: string): string {
    return `${this.ORDER_WITH_LINE(orderId, linePosition)}/proposal`;
  }

  public ORDER_WITH_LINE_PROPOSAL_ACCEPT(orderId: string, linePosition: string): string {
    return `${this.ORDER_WITH_LINE_PROPOSAL(orderId, linePosition)}/approve`;
  }

  public ORDER_WITH_LINE_PROPOSAL_REJECT(orderId: string, linePosition: string): string {
    return `${this.ORDER_WITH_LINE_PROPOSAL(orderId, linePosition)}/reject`;
  }

  public GEO_IP(): string {
    return `https://api.ipbase.com/v1/json/?apikey=${environment.ipBaseGeoKey}`;
  }
}
