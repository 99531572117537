import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BuyerDelivery, BuyerItem, LineDelivery, OrderLine } from '@app/order/models';
import { MergeDeliverySchedulePipe } from '@app/order/shared/pipes/merge-delivery-schedule.pipe';
import { quantityDifference } from '@app/order/shared/components/quantity-difference/helpers/quantity-difference.helper';
import { hasOpenBuyerReopenRequest, hasOpenSupplierReopenRequest } from '@app/order/util/helper';
import { dateDifference } from '@app/order/shared/components/date-difference/helpers/date-difference.helper';
import { defaultLineDetailColumns } from '@app/order/shared/components/line-details/line-details.component';

@Component({
  selector: 'tc-line-schedule',
  templateUrl: './line-schedule.component.html',
  styleUrls: ['./line-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineScheduleComponent {
  public get columnDefs(): string[] {
    return defaultLineDetailColumns;
  }

  @Input()
  orderLine?: OrderLine;

  constructor(private mergeDelivery: MergeDeliverySchedulePipe) {}

  public get item(): BuyerItem | undefined {
    return this.orderLine?.buyerLine.item;
  }

  public get deliverySchedule(): BuyerDelivery[] | LineDelivery[] {
    return this.orderLine ? this.mergeDelivery.deliverySchedule(this.orderLine) : [];
  }

  public get displayedMerged(): LineDelivery[] {
    return this.orderLine ? this.mergeDelivery.transform(this.orderLine) : [];
  }

  public get deliveryQuantityChanged(): boolean | undefined {
    return (
      this.requestDeliverySchedule &&
      this.persistedDeliverySchedule &&
      (this.requestDeliverySchedule.length !== this.persistedDeliverySchedule.length ||
        this.requestDeliverySchedule?.some((item, index) => {
          return this.hasDeliveryQuantityChanged(index);
        }))
    );
  }

  public get isDeliveryDecrease(): boolean {
    return (
      !!this.persistedDeliverySchedule &&
      !!this.requestDeliverySchedule &&
      this.requestDeliverySchedule.length < this.persistedDeliverySchedule.length
    );
  }

  public isDeliveryNew(index: number): boolean {
    return (
      !!this.persistedDeliverySchedule &&
      !!this.requestDeliverySchedule &&
      this.requestDeliverySchedule[index] &&
      this.persistedDeliverySchedule[index] === undefined
    );
  }

  public hasDeliveryDateChanged(index: number): boolean {
    return !!dateDifference(this.requestDeliverySchedule?.[index]?.date, this.persistedDeliverySchedule?.[index]?.date);
  }

  public hasDeliveryQuantityChanged(index: number): boolean {
    return !!quantityDifference(
      this.requestDeliverySchedule?.[index]?.quantity,
      this.persistedDeliverySchedule?.[index]?.quantity,
    );
  }

  private get requestDeliverySchedule(): BuyerDelivery[] | undefined {
    if (
      hasOpenBuyerReopenRequest(this.orderLine!) &&
      this.orderLine!.buyerLine.requests!.reopenRequest!.deliverySchedule
    ) {
      return this.orderLine!.buyerLine.requests!.reopenRequest!.deliverySchedule;
    }

    if (
      hasOpenSupplierReopenRequest(this.orderLine!) &&
      this.orderLine!.supplierLine.requests.reopenRequest!.deliverySchedule
    ) {
      return this.orderLine!.supplierLine.requests.reopenRequest!.deliverySchedule;
    }

    if (
      this.orderLine?.supplierLine.requests?.proposal?.status === 'Open' &&
      this.orderLine.supplierLine.requests.proposal.deliverySchedule
    ) {
      return this.orderLine.supplierLine.requests.proposal.deliverySchedule;
    }
  }

  private get persistedDeliverySchedule(): LineDelivery[] | undefined {
    return this.orderLine!.deliverySchedule;
  }
}
