import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, filter, map, mapTo, withLatestFrom } from 'rxjs/operators';
import { TaskService } from '@app/task/core/services/task.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationType } from '@app/core/state/core.model';
import { EMPTY, merge, of } from 'rxjs';
import { notificationSend } from '@app/core/state/core.actions';
import {
  forecastTasksAcknowledgeClose,
  orderTasksAcknowledgeClose,
  shipmentTaskClose,
  taskClose,
  taskCloseFailure,
  taskCloseSuccess,
  taskListen,
  taskWebSocketUpsert,
  taskWebSocketUpsertWithoutFilter,
} from './task.actions';
import {
  AssignedTo,
  CloseConversationTask,
  CloseForecastAcknowledgeTasks,
  CloseOrderAcknowledgeTasks,
  CloseShipmentTasks,
} from '@app/task/models';
import { select, Store } from '@ngrx/store';
import { selectTaskListAssignedToFilter } from '@app/task/routes/task-list/state/task-list.selectors';
import { TaskListState } from '@app/task/routes/task-list/state/task-list.reducer';
import { selectAuthUserId } from '@app/auth/core/state/auth.selectors';

@Injectable()
export class TaskEffects {
  listen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(taskListen),
      map(({ companyId }) => {
        return companyId;
      }),
      concatMap(companyId => {
        return this.taskService.websocket$(companyId, undefined, undefined).pipe(
          map(entity => {
            return { entity };
          }),
        );
      }),
      map(
        ({ entity }) => {
          return taskWebSocketUpsertWithoutFilter({ entity });
        },
        catchError(() => {
          return EMPTY;
        }),
      ),
    );
  });

  listenWithFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(taskListen),
      map(({ companyId }) => {
        return companyId;
      }),
      withLatestFrom(this.store$.pipe(select(selectAuthUserId))),
      concatMap(([companyId, userId]) => {
        const assignedList = [AssignedTo.COMPANY, AssignedTo.SUPPLIERS, AssignedTo.BUYERS, undefined];

        const taskWebsockets$ = assignedList.map(key => {
          return this.taskService.websocket$(companyId, key, userId as string).pipe(
            map(entity => {
              return { entity, assignedTo: key };
            }),
          );
        });

        return merge(...taskWebsockets$)
          .pipe(
            withLatestFrom(this.store$.pipe(select(selectTaskListAssignedToFilter))),
            filter(([{ assignedTo }, userSelectedKey]) => {
              return assignedTo === userSelectedKey;
            }),
          )
          .pipe(
            map(
              ([{ entity }]) => {
                return taskWebSocketUpsert({ entity });
              },
              catchError(() => {
                return EMPTY;
              }),
            ),
          );
      }),
    );
  });

  closeTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(taskClose),
      concatMap(({ entityId, entityType, messageId }: CloseConversationTask) => {
        return this.taskService.closeTask$({ messageId, entityType, entityId }).pipe(
          mapTo(taskCloseSuccess()),
          catchError(({ error }: HttpErrorResponse) => {
            return of(taskCloseFailure({ error: error.message || error }));
          }),
        );
      }),
    );
  });

  closeShipmentTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentTaskClose),
      concatMap(({ shipmentId, taskIds }: CloseShipmentTasks) => {
        return this.taskService.closeShipmentTasks$({ shipmentId, taskIds }).pipe(
          mapTo(taskCloseSuccess()),
          catchError(({ error }: HttpErrorResponse) => {
            return of(taskCloseFailure({ error: error.message || error }));
          }),
        );
      }),
    );
  });

  closeOrderAcknowledgeTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderTasksAcknowledgeClose),
      concatMap(({ companyId, orderId, taskIds }: CloseOrderAcknowledgeTasks) => {
        return this.taskService.closeOrderAcknowledgeTasks$({ companyId, taskIds, orderId }).pipe(
          mapTo(taskCloseSuccess()),
          catchError(({ error }: HttpErrorResponse) => {
            return of(taskCloseFailure({ error: error.message || error }));
          }),
        );
      }),
    );
  });

  closeForecastAcknowledgeTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(forecastTasksAcknowledgeClose),
      concatMap(({ forecastId, taskIds }: CloseForecastAcknowledgeTasks) => {
        return this.taskService.closeForecastAcknowledgeTasks$({ forecastId, taskIds }).pipe(
          mapTo(taskCloseSuccess()),
          catchError(({ error }: HttpErrorResponse) => {
            return of(taskCloseFailure({ error: error.message || error }));
          }),
        );
      }),
    );
  });

  requestSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(taskCloseSuccess),
      mapTo(
        notificationSend({
          message: $localize`:@@ts.task.close.success:Task closed`,
          notificationType: NotificationType.SUCCESS,
        }),
      ),
    );
  });

  requestFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(taskCloseFailure),
      mapTo(
        notificationSend({
          message: $localize`:@@ts.task.close.failed:Failed to close task. Please try again.`,
          notificationType: NotificationType.ERROR,
        }),
      ),
    );
  });

  constructor(private actions$: Actions, private taskService: TaskService, private store$: Store<TaskListState>) {}
}
