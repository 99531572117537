import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { getRegressionSeries } from '@app/analytics/util/chart-regression';
import { Measurement } from '@app/analytics/models';

@Component({
  selector: 'tc-line-chart',
  template: '<highcharts-chart [Highcharts]="Highcharts" [options]="options"></highcharts-chart>',
  styleUrls: ['./line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineChartComponent {
  @Input()
  measurements: Measurement[] = [];

  @Input()
  yAxisTitle?: string;

  @Input()
  dataLabelsFormat = '{y}';

  @Input()
  regressionPointFormat = '{series.name}: <b>{point.y}</b>';

  public readonly Highcharts = Highcharts;

  public get options(): Highcharts.Options {
    const data: number[] = [];
    const categories: string[] = [];

    this.measurements.forEach(measurement => {
      const category = moment.utc(measurement.date).format('MMM, YY');

      categories.push(category);
      data.push(measurement.value);
    });

    const regressionSeries = getRegressionSeries(
      this.measurements.map(({ date, value }) => {
        return {
          x: date.getTime(),
          y: value,
        };
      }),
      {
        color: '#90caf9',
        pointFormat: this.regressionPointFormat,
      },
    );

    regressionSeries.data = regressionSeries.data!.map(datum => {
      const datumTyped = datum as [number, number];

      return datumTyped[1];
    });

    const yAxisMax = data.length
      ? Math.max(...data) + 5 // 5 to give a bit of air on the top
      : 100;

    return {
      chart: {
        style: {
          fontFamily: 'inherit',
        },
        type: 'line',
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        line: {
          color: '#448aff',
          dataLabels: {
            enabled: true,
            format: this.dataLabelsFormat,
          },
          marker: {
            symbol: 'circle',
          },
        },
      },
      series: [
        regressionSeries,
        {
          data,
          enableMouseTracking: false,
          id: Date.now().toString(16),
          type: 'line',
        },
      ],
      title: {
        text: '',
      },
      tooltip: {
        enabled: true,
        headerFormat: '',
      },
      xAxis: {
        categories,
      },
      yAxis: {
        max: yAxisMax,
        min: 0,
        title: {
          text: this.yAxisTitle,
        },
      },
    };
  }
}
