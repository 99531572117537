import { forwardRef, Provider } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

/* eslint-disable @angular-eslint/no-forward-ref */
export function getFormControlProviders<T>(component: T): Provider[] {
  return [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => {
        return component;
      }),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => {
        return component;
      }),
      multi: true,
    },
  ];
}

export abstract class FormControlComponent implements ControlValueAccessor, Validator {
  public abstract control: FormControl;

  writeValue(value: string): void {
    this.control.setValue(value);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.control.valueChanges.subscribe(value => {
      fn(value);
    });
  }

  registerOnTouched(fn: any): void {
    // noop
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  validate(): ValidationErrors | null {
    return this.control.errors;
  }
}
