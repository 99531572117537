import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChargeLine, OrderLineSearchView } from '@app/order/models';
import { quantityDifference } from '@app/order/shared/components/quantity-difference/helpers/quantity-difference.helper';
import { priceDifference } from '@app/order/shared/components/price-difference/helpers/price-difference.helper';
import { CHARGE_TYPES } from '@app/order/routes/order-line-detail/components/line-dialog/charge-types';
import { requestedChargeLines } from '@app/order/util/helper';

@Component({
  selector: 'tc-charge-line-table',
  templateUrl: './charge-line-table.component.html',
  styleUrls: ['./charge-line-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChargeLineTableComponent {
  public readonly columnDefs = ['position', 'type', 'description', 'quantity', 'price'];

  public readonly chargeTypeMap: Map<string, string> = new Map(
    CHARGE_TYPES.map((i): [string, string] => {
      return [i.value, i.label];
    }),
  );

  @Input()
  orderLine!: OrderLineSearchView;

  public isQuantityChanged(index: number): boolean {
    return !!quantityDifference(
      this.requestedChargeLines?.[index]?.quantity,
      this.persistedChargeLines?.[index]?.quantity,
    );
  }

  public isPriceUnitOfMeasureChanged(index: number): boolean | undefined {
    return (
      !!this.persistedChargeLines?.[index]?.priceUnitOfMeasureIso &&
      !!this.requestedChargeLines?.[index]?.priceUnitOfMeasureIso &&
      this.persistedChargeLines[index].priceUnitOfMeasureIso !== this.requestedChargeLines[index].priceUnitOfMeasureIso
    );
  }

  public isPriceChanged(index: number): boolean {
    return (
      this.isPriceTransactionCurrencyChanged(index) ||
      !!priceDifference(this.persistedChargeLines?.[index]?.price, this.requestedChargeLines?.[index]?.price)
    );
  }

  public isChargeTypeCodeChanged(index: number): boolean {
    return this.requestedChargeLines?.[index]?.chargeTypeCode !== this.persistedChargeLines?.[index]?.chargeTypeCode;
  }

  public get requestedChargeLines(): ChargeLine[] {
    return requestedChargeLines(this.orderLine);
  }

  private get persistedChargeLines(): ChargeLine[] {
    return this.orderLine?.confirmedLine?.chargeLines
      ? this.orderLine.confirmedLine.chargeLines
      : this.orderLine.buyerLine.chargeLines;
  }

  private isPriceTransactionCurrencyChanged(index: number): boolean {
    return (
      !!this.persistedChargeLines?.[index]?.price.priceInTransactionCurrency &&
      !!this.requestedChargeLines?.[index]?.price?.priceInTransactionCurrency &&
      this.persistedChargeLines?.[index]?.price.priceInTransactionCurrency.currencyIso !==
        this.requestedChargeLines?.[index]?.price?.priceInTransactionCurrency.currencyIso
    );
  }
}
