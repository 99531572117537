import {ChangeDetectionStrategy, Component, Input, ViewChild, ElementRef} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { Message } from '@app/conversation/models';
import { FormComponent } from '@app/shared/abstracts/form.component';
import { UnobtrusiveErrorStateMatcher } from './unobtrusive-error-state-matcher.class';
import { MixpanelButtonClickEvents } from '@app/core/services/page-analytics/enums/mixpanel-button-click-events.enum';

@Component({
  selector: 'tc-send-message-form',
  templateUrl: './send-message-form.component.html',
  styleUrls: ['./send-message-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendMessageFormComponent extends FormComponent<Message, Message & { internal: 0 | 1 }> {
  public readonly LABEL_INTERNAL = $localize`:@@ts.conversation.label.internal:Type your internal note`;
  public readonly LABEL_MESSAGE = $localize`:@@ts.conversation.label.message:Type your message`;

  public readonly BUTTON_INTERNAL = $localize`:@@ts.conversation.button.internal:Send internal note`;
  public readonly BUTTON_MESSAGE = $localize`:@@ts.conversation.button.message:Send Message`;

  @ViewChild(MatInput, { static: true })
  matInput!: MatInput;

  public mixpanelClickEvents = MixpanelButtonClickEvents;

  @Input()
  mixpanelClickEvent: string = MixpanelButtonClickEvents.ORDER_SEND_MESSAGE;

  @Input()
  hasInternal!: boolean;

  @ViewChild('message')
  inputMessage!: ElementRef<HTMLTextAreaElement>;

  public form = new FormGroup({
    message: new FormControl(null, Validators.required),
    internal: new FormControl(0),
  });

  get internalControl() {
    return this.form.get('internal');
  }

  public matcher = new UnobtrusiveErrorStateMatcher();

  public get normalizedFormValue() {
    const { message, internal } = this.form.value;

    return {
      message,
      internal: !!internal,
    };
  }

  public submit(): void {
    super.submit();

    this.form.get('message')?.reset();
    this.matInput.focus();

    // Reset field
    const element = this.inputMessage.nativeElement;
    const textarea = element as HTMLTextAreaElement;
    textarea.style.height = '22px';
  }

  enforceLineLimit(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;

    textarea.style.height = '1px';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }
}
