import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  BuyerDelivery,
  BuyerItem,
  Certification,
  Dimension,
  LineDelivery,
  Money,
  OrderLine,
  Prices,
} from '@app/order/models';
import { priceDifference } from '@app/order/shared/components/price-difference/helpers/price-difference.helper';
import { quantityDifference } from '@app/order/shared/components/quantity-difference/helpers/quantity-difference.helper';
import { dateDifference } from '@app/order/shared/components/date-difference/helpers/date-difference.helper';
import { MergeDeliverySchedulePipe } from '@app/order/shared/pipes/merge-delivery-schedule.pipe';
import { hasOpenBuyerReopenRequest, hasOpenSupplierReopenRequest } from '@app/order/util/helper';

@Component({
  selector: 'tc-line-details',
  templateUrl: './line-details.component.html',
  styleUrls: ['./line-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineDetailsComponent {
  public get columnDefs(): string[] {
    return this.columns;
  }

  @Input()
  columns!: string[];

  public get item(): BuyerItem | undefined {
    return this.orderLine?.buyerLine.item;
  }

  public get dimensions(): Dimension[] {
    return this.orderLine?.buyerLine?.item.dimensions || [];
  }

  public get certification(): Certification | undefined {
    return this.orderLine?.buyerLine.certification;
  }

  public get amountView(): Money | undefined {
    return this.orderLine?.totalAmountIncludingRequests;
  }

  public get amountChanged(): boolean | undefined {
    return (
      this.orderLine?.totalAmountIncludingRequests?.value !== this.orderLine?.totalAmount?.value ||
      this.orderLine?.totalAmountIncludingRequests?.currencyIso !== this.orderLine?.totalAmount?.currencyIso
    );
  }

  public get deliveryScheduleChanged(): boolean | undefined {
    return (
      this.requestDeliverySchedule &&
      this.persistedDeliverySchedule &&
      (this.requestDeliverySchedule?.length !== this.persistedDeliverySchedule?.length ||
        this.requestDeliverySchedule?.some((item, index) => {
          return this.hasDeliveryQuantityChanged(index) || this.hasDeliveryDateChanged(index);
        }))
    );
  }

  public get deliveryQuantityChanged(): boolean | undefined {
    return (
      this.requestDeliverySchedule &&
      this.persistedDeliverySchedule &&
      (this.requestDeliverySchedule.length !== this.persistedDeliverySchedule.length ||
        this.requestDeliverySchedule?.some((item, index) => {
          return this.hasDeliveryQuantityChanged(index);
        }))
    );
  }

  public get displayedMerged(): LineDelivery[] {
    return this.orderLine ? this.mergeDelivery.transform(this.orderLine) : [];
  }

  public get deliverySchedule(): BuyerDelivery[] | LineDelivery[] {
    return this.orderLine ? this.mergeDelivery.deliverySchedule(this.orderLine) : [];
  }

  public get deliveryHistory(): BuyerDelivery[] | undefined {
    return this.orderLine?.confirmedLine?.deliveryHistory || this.orderLine?.buyerLine.deliveryHistory;
  }

  public get discountPercentageChanged(): boolean | undefined {
    return (
      this.requestPrices &&
      this.persistedPrices &&
      this.persistedPrices.discountPercentage !== this.requestPrices.discountPercentage
    );
  }

  public get grossPriceChanged(): boolean {
    return (
      this.hasGrossPriceTransactionCurrencyChanged() ||
      (this.pricePerItemChanged &&
        !!this.persistedPrices?.grossPrice &&
        !!priceDifference(this.persistedPrices.grossPrice, this.requestPrices?.grossPrice))
    );
  }

  public get netPriceChanged(): boolean | undefined {
    return this.hasNetPriceTransactionCurrencyChanged() || this.pricePerItemChanged;
  }

  public get prices(): Prices | undefined {
    if (hasOpenBuyerReopenRequest(this.orderLine!) && this.orderLine!.buyerLine.requests!.reopenRequest!.prices) {
      return this.orderLine!.buyerLine.requests!.reopenRequest!.prices;
    }

    if (hasOpenSupplierReopenRequest(this.orderLine!) && this.orderLine!.supplierLine.requests.reopenRequest!.prices) {
      return this.orderLine!.supplierLine.requests.reopenRequest!.prices;
    }

    return (
      this.orderLine?.confirmedLine?.prices ||
      (this.isProposed() && this.orderLine!.supplierLine.requests.proposal!.prices) ||
      this.orderLine?.buyerLine.prices
    );
  }

  private get pricePerItemChanged(): boolean {
    return (
      !!this.persistedPrices?.netPrice &&
      !!this.requestPrices?.netPrice &&
      this.persistedPrices.netPrice.priceInTransactionCurrency.value / this.persistedPrices.priceUnitQuantity !==
        this.requestPrices.netPrice.priceInTransactionCurrency.value / this.requestPrices.priceUnitQuantity
    );
  }

  private get requestDeliverySchedule(): BuyerDelivery[] | undefined {
    if (
      hasOpenBuyerReopenRequest(this.orderLine!) &&
      this.orderLine!.buyerLine.requests!.reopenRequest!.deliverySchedule
    ) {
      return this.orderLine!.buyerLine.requests!.reopenRequest!.deliverySchedule;
    }

    if (
      hasOpenSupplierReopenRequest(this.orderLine!) &&
      this.orderLine!.supplierLine.requests.reopenRequest!.deliverySchedule
    ) {
      return this.orderLine!.supplierLine.requests.reopenRequest!.deliverySchedule;
    }

    if (
      this.orderLine?.supplierLine.requests?.proposal?.status === 'Open' &&
      this.orderLine.supplierLine.requests.proposal.deliverySchedule
    ) {
      return this.orderLine.supplierLine.requests.proposal.deliverySchedule;
    }
  }

  private get persistedDeliverySchedule(): LineDelivery[] | undefined {
    return this.orderLine!.deliverySchedule;
  }

  private get requestPrices(): Prices | undefined {
    if (this.orderLine) {
      if (hasOpenBuyerReopenRequest(this.orderLine) && this.orderLine.buyerLine.requests!.reopenRequest!.prices) {
        return this.orderLine.buyerLine.requests!.reopenRequest!.prices;
      }

      if (hasOpenSupplierReopenRequest(this.orderLine) && this.orderLine.supplierLine.requests.reopenRequest!.prices) {
        return this.orderLine.supplierLine.requests.reopenRequest!.prices;
      }
      if (
        this.orderLine.supplierLine.requests?.proposal?.status === 'Open' &&
        this.orderLine.supplierLine.requests.proposal.prices
      ) {
        return this.orderLine.supplierLine.requests.proposal.prices;
      }
    }
  }

  private get persistedPrices(): Prices | undefined {
    return this.orderLine?.confirmedLine?.prices || this.orderLine?.buyerLine.prices;
  }

  public get priceUnitChanged(): boolean | undefined {
    return (
      this.requestPrices &&
      this.persistedPrices &&
      (this.requestPrices.priceUnitOfMeasureIso !== this.persistedPrices.priceUnitOfMeasureIso ||
        (this.pricePerItemChanged && this.requestPrices.priceUnitQuantity !== this.persistedPrices.priceUnitQuantity))
    );
  }

  public get isDeliveryDecrease(): boolean {
    return (
      !!this.persistedDeliverySchedule &&
      !!this.requestDeliverySchedule &&
      this.requestDeliverySchedule.length < this.persistedDeliverySchedule.length
    );
  }

  public get contractNumber(): string | undefined {
    return this.orderLine?.buyerLine.terms?.contractNumber;
  }

  public get contractPosition(): string | undefined {
    return this.orderLine?.buyerLine.terms?.contractPosition;
  }

  @Input()
  layout: 'column' | 'row' = 'column';

  @Input()
  orderLine?: OrderLine;

  public readonly fxOptions = {
    column: { flex: '', gap: 'margin-bottom' },
    row: { flex: 50, gap: '40px' },
  };

  constructor(private mergeDelivery: MergeDeliverySchedulePipe) {}

  public isDeliveryNew(index: number): boolean {
    return (
      !!this.persistedDeliverySchedule &&
      !!this.requestDeliverySchedule &&
      this.requestDeliverySchedule[index] &&
      this.persistedDeliverySchedule[index] === undefined
    );
  }

  public hasDeliveryDateChanged(index: number): boolean {
    return !!dateDifference(this.requestDeliverySchedule?.[index]?.date, this.persistedDeliverySchedule?.[index]?.date);
  }

  public hasDeliveryQuantityChanged(index: number): boolean {
    return !!quantityDifference(
      this.requestDeliverySchedule?.[index]?.quantity,
      this.persistedDeliverySchedule?.[index]?.quantity,
    );
  }

  private hasNetPriceTransactionCurrencyChanged(): boolean {
    return (
      !!this.persistedPrices?.netPrice?.priceInTransactionCurrency &&
      !!this.requestPrices?.netPrice?.priceInTransactionCurrency &&
      this.persistedPrices.netPrice.priceInTransactionCurrency.currencyIso !==
        this.requestPrices.netPrice.priceInTransactionCurrency.currencyIso
    );
  }

  private hasGrossPriceTransactionCurrencyChanged(): boolean {
    return (
      !!this.persistedPrices?.grossPrice?.priceInTransactionCurrency &&
      !!this.requestPrices?.grossPrice?.priceInTransactionCurrency &&
      this.persistedPrices.grossPrice.priceInTransactionCurrency.currencyIso !==
        this.requestPrices.grossPrice.priceInTransactionCurrency.currencyIso
    );
  }

  private isProposed = () => {
    if (!this.orderLine || !this.orderLine.supplierLine.requests.proposal) {
      return false;
    }

    return this.orderLine.supplierLine.requests.proposal.status === 'Open';
  };
}

export const defaultLineDetailColumns = ['position', 'date', 'quantity', 'status', 'transport', 'etd', 'eta'];

export const compactLineDetailColumns = ['position', 'date', 'quantity'];
