import { NgModule } from '@angular/core';
import { RootSharedModule } from '@app/shared/root-shared.module';
import { CropperDialogComponent } from './components/cropper-dialog/cropper-dialog.component';
import { DownloadDocumentComponent } from './components/download-document/download-document.component';
import { FileSelectComponent } from './components/file-select/file-select.component';
import { UploadPictureComponent } from './components/upload-picture/upload-picture.component';
import { ObjectStoragePipe } from './pipes/object-storage.pipe';

const components = [CropperDialogComponent, DownloadDocumentComponent, FileSelectComponent, UploadPictureComponent];
const pipes = [ObjectStoragePipe];

@NgModule({
  imports: [RootSharedModule],
  declarations: [components, pipes],
  entryComponents: [CropperDialogComponent],
  exports: [components, pipes],
})
export class ObjectStorageSharedModule {}
