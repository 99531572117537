import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';

import { AnalyticsRange, Measurement } from '@app/analytics/models';
import { getRegressionSeries } from '@app/analytics/util/chart-regression';
import { getSeriesColor, getSeriesName, normalizeMeasurement } from './helpers/highchart.helper';

@Component({
  selector: 'tc-supplier-reliability-metric',
  template: '<highcharts-chart [Highcharts]="Highcharts" [options]="options"></highcharts-chart>',
  styleUrls: ['./supplier-reliability-metric.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplierReliabilityMetricComponent {
  @Input()
  kpisConfirmed!: Measurement[];

  @Input()
  kpisRequested!: Measurement[];

  @Input()
  startDate!: Date;

  @Input()
  endDate!: Date;

  @Input()
  range!: AnalyticsRange;

  public readonly Highcharts = Highcharts;

  public get options(): Highcharts.Options {
    return {
      chart: {
        type: 'column',
        zoomType: 'xy',
      },
      noData: {
        position: {
          align: 'center',
          verticalAlign: 'top',
          y: 70,
        },
      },
      plotOptions: {
        series: {
          pointStart: this.startDate.valueOf(),
          turboThreshold: 10000,
        },
      },
      series: this.series,
      title: {
        text: moment(this.startDate).format(this.titleFormat),
      },
      xAxis: {
        max: this.endDate.valueOf(),
        min: this.startDate.valueOf(),
        showEmpty: true,
        type: 'datetime',
        ...this.xAxisOptions,
      },
      yAxis: {
        title: { text: 'KPI, %' },
        min: 0,
        max: 100,
      },
    };
  }

  private get series(): Highcharts.SeriesOptionsType[] {
    const series: Highcharts.SeriesOptionsType[] = [];

    const confirmed = this.kpisConfirmed.map(normalizeMeasurement);
    const requested = this.kpisRequested.map(normalizeMeasurement);

    this.addSeries(series, confirmed, 'confirmed');
    this.addSeries(series, requested, 'requested');

    return series;
  }

  private get titleFormat(): string {
    if (this.range === 'year') {
      return 'YYYY';
    }

    if (this.range === 'month') {
      return 'MMMM YYYY';
    }

    return '[Week] W, YYYY';
  }

  private get xAxisOptions(): Highcharts.XAxisOptions {
    if (this.range === 'year') {
      return {
        dateTimeLabelFormats: {
          month: { main: '%B %Y' },
        },
        tickInterval: 24 * 3600 * 1000 * 28, // 28 days
      };
    }

    if (this.range === 'month') {
      return {
        dateTimeLabelFormats: {
          month: { main: '%B %Y' },
          day: { main: '%e' },
        },
        tickInterval: 24 * 3600 * 1000, // 24 hours
      };
    }

    return {
      dateTimeLabelFormats: {
        day: { main: '%a %e %b' },
      },
      tickInterval: 24 * 3600 * 1000, // 24 hours
    };
  }

  private addSeries(
    series: Highcharts.SeriesOptionsType[],
    data: { x: number; y: number; description?: string }[],
    type: 'confirmed' | 'requested',
  ): void {
    const confirmed = type === 'confirmed';
    const tooltipOptions = {
      headerFormat: '',
      xDateFormat: '%Y-%m-%d',
      valueSuffix: ' %',
      valueDecimals: 1,
    };

    if (this.range === 'week') {
      series.push({
        color: getSeriesColor(confirmed, false),
        data,
        id: type,
        name: getSeriesName(confirmed, false),
        tooltip: {
          ...tooltipOptions,
          pointFormat: '<b>{point.description}</b><br>{series.name}: <b>{point.y}</b>',
        },
        type: 'scatter',
      });

      if (data.length) {
        series.push(
          getRegressionSeries(data, {
            color: getSeriesColor(confirmed, true),
            name: getSeriesName(confirmed, true),
            pointFormat: '{series.name}: <b>{point.y} %</b>',
          }),
        );
      }
    } else {
      series.push({
        color: getSeriesColor(confirmed, false),
        data,
        id: type,
        name: getSeriesName(confirmed, false),
        tooltip: {
          ...tooltipOptions,
          pointFormat: '{series.name}: <b>{point.y}</b>',
        },
        type: 'bar',
      });
    }
  }
}
