<ng-container
  *ngIf="{
    config: panelsConfig$ | async,
    order: order$ | async
  } as vm"
>
  <mat-accordion
    (cdkDropListDropped)="drop($event, vm.config)"
    *ngIf="{
      summary: expandPanelSummary$ | async,
      notes: expandPanelNotes$ | async,
      properties: expandPanelProperties$ | async,
      destination: expandPanelDestination$ | async,
      terms: expandPanelTerms$ | async,
      documents: expandPanelDocuments$ | async,
      invoice: expandPanelInvoice$ | async,
      contacts: expandPanelContacts$ | async
    } as expand"
    [style]="{ display: 'flex', 'flex-direction': 'column' }"
    cdkDropList
    multi
  >
    <mat-expansion-panel
      [cdkDragData]="panelType.SUMMARY"
      [expanded]="expand.summary"
      [style.order]="vm.config.summary"
      cdkDrag
    >
      <mat-expansion-panel-header
        (click)="togglePanel(panelType.SUMMARY, expand.summary)"
      >
        <mat-panel-title>
          <strong i18n="@@common.order.summary">Order summary</strong>
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon (click)="$event.stopPropagation()" cdkDragHandle class="icon-move">open_with</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <tc-order-summary
        [order]="order$ | async"
        [role]="role$ | async"
        [companyId]="actingAsCompanyId$ | async"
        (removeOrderLabel)="onRemoveLabel($event)"
      ></tc-order-summary>
    </mat-expansion-panel>

    <ng-container *ngIf="vm.order?.supplierOrder?.notes?.length > 0 || vm.order?.buyerOrder?.notes?.length > 0">
      <mat-expansion-panel
        [cdkDragData]="panelType.NOTES"
        [expanded]="expand.notes"
        [style.order]="vm.config.notes"
        cdkDrag
      >
        <mat-expansion-panel-header
          (click)="togglePanel(panelType.NOTES, expand.notes)"
        >
          <mat-panel-title>
            <strong i18n="@@common.field.notes">Notes</strong>
          </mat-panel-title>
          <mat-panel-description>
            <mat-icon (click)="$event.stopPropagation()" cdkDragHandle class="icon-move">open_with</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <tc-notes [order]="order$ | async"></tc-notes>
      </mat-expansion-panel>
    </ng-container>

    <ng-container *ngIf="vm.order?.buyerOrder?.properties?.length > 0 || vm.order?.supplierOrder?.properties?.length > 0">
      <mat-expansion-panel
        [cdkDragData]="panelType.PROPERTIES"
        [expanded]="expand.properties"
        [style.order]="vm.config.properties"
        cdkDrag
      >
        <mat-expansion-panel-header
          (click)="togglePanel(panelType.PROPERTIES, expand.properties)"
        >
          <mat-panel-title>
            <strong i18n="@@common.field.properties">Properties</strong>
          </mat-panel-title>
          <mat-panel-description>
            <mat-icon (click)="$event.stopPropagation()" cdkDragHandle class="icon-move">open_with</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <tc-order-properties [order]="order$ | async"></tc-order-properties>
      </mat-expansion-panel>
    </ng-container>

    <mat-expansion-panel
      [cdkDragData]="panelType.DESTINATION"
      [expanded]="expand.destination"
      [style.order]="vm.config.destination"
      cdkDrag
    >
      <mat-expansion-panel-header
        (click)="togglePanel(panelType.DESTINATION, expand.destination)"
      >
        <mat-panel-title class="panel-title">
          <strong i18n="@@common.field.destination">Destination</strong>
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon (click)="$event.stopPropagation()" cdkDragHandle class="icon-move">open_with</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <tc-order-destination [order]="order$ | async"></tc-order-destination>
    </mat-expansion-panel>

    <ng-container *ngIf="vm.order?.buyerOrder | tcHasAnyValues : 'terms'">
      <mat-expansion-panel
        [cdkDragData]="panelType.TERMS"
        [expanded]="expand.terms"
        [style.order]="vm.config.terms"
        cdkDrag
      >
        <mat-expansion-panel-header
          (click)="togglePanel(panelType.TERMS, expand.terms)"
        >
          <mat-panel-title>
            <strong i18n="@@common.field.terms">Terms</strong>
          </mat-panel-title>
          <mat-panel-description>
            <mat-icon (click)="$event.stopPropagation()" cdkDragHandle class="icon-move">open_with</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <tc-order-terms
          [order]="order$ | async"
        ></tc-order-terms>
      </mat-expansion-panel>
    </ng-container>

    <ng-container *ngIf="vm.order?.buyerOrder | tcHasAnyValues : 'buyerAccountingParty'">
      <mat-expansion-panel
        [cdkDragData]="panelType.INVOICE"
        [expanded]="expand.invoice"
        [style.order]="vm.config.invoice"
        cdkDrag
      >
        <mat-expansion-panel-header
          (click)="togglePanel(panelType.INVOICE, expand.invoice)"
        >
          <mat-panel-title>
            <strong i18n="@@common.field.invoice">Invoice</strong>
          </mat-panel-title>
          <mat-panel-description>
            <mat-icon (click)="$event.stopPropagation()" cdkDragHandle class="icon-move">open_with</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <tc-order-invoice
          [order]="order$ | async"
        ></tc-order-invoice>
      </mat-expansion-panel>
    </ng-container>

    <mat-expansion-panel
      [cdkDragData]="panelType.CONTACTS"
      [expanded]="expand.contacts"
      [style.order]="vm.config.contacts"
      cdkDrag
    >
      <mat-expansion-panel-header
        (click)="togglePanel(panelType.CONTACTS, expand.contacts)"
      >
        <mat-panel-title>
          <strong i18n="@@common.field.contacts">Contacts</strong>
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon (click)="$event.stopPropagation()" cdkDragHandle class="icon-move">open_with</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <tc-contact-info
        (assign)="onAssign($event, vm.order)"
        [assignEnabled]="true"
        [order]="order$ | async"
        [role]="role$ | async"
      ></tc-contact-info>
    </mat-expansion-panel>

    <ng-container *ngIf="vm.order?.supplierOrder?.documents?.length > 0 || vm.order?.buyerOrder?.documents?.length > 0">
      <mat-expansion-panel
      [cdkDragData]="panelType.DOCUMENTS"
      [expanded]="expand.documents"
      [style.order]="vm.config.documents"
      cdkDrag
    >
      <mat-expansion-panel-header
        (click)="togglePanel(panelType.DOCUMENTS, expand.documents)"
      >
        <mat-panel-title>
          <strong i18n="@@common.field.documents">Documents</strong>
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon (click)="$event.stopPropagation()" cdkDragHandle class="icon-move">open_with</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <tc-order-documents
        [order]="order$ | async"
      ></tc-order-documents>
    </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</ng-container>
