import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormComponent } from '@app/shared/abstracts/form.component';
import { TwoFACode } from '@app/auth/core/state/auth.model';
import { Configure2FA } from '@app/auth/core/state/auth.reducer';

@Component({
  selector: 'tc-setup-2fa-step-3',
  templateUrl: './setup-2fa-step3.component.html',
  styleUrls: ['../base/two-factor-authentication.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Setup2FAStep3Component extends FormComponent<TwoFACode> implements OnChanges {
  @Input()
  configure2FA!: Configure2FA;

  public form = new FormGroup({
    code: new FormControl(
      '',
      Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
    ),
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.configure2FA && changes.configure2FA.currentValue !== changes.configure2FA.previousValue) {
      const errorCode = this.configure2FA.enable2FAErrorCode;
      if (errorCode) {
        const error =
          errorCode === 'identity.2fa.invalid_code'
            ? { [errorCode]: true }
            : { ['identity.2fa.validation_error']: true };
        this.form.controls.code.setErrors(error);
      }
    }
  }
}
