export enum MixpanelButtonClickEvents {
  RFQ_OPEN = 'RFQ open',
  RFQ_LINE_OPEN = 'RFQ line open',

  RFQ_OPEN_FROM_ACTIVITY = 'RFQ order open from activity',
  RFQ_LINE_OPEN_FROM_ACTIVITY = 'RFQ order line open from activity',

  USER_INVITED = 'User invited',
  CONNECTION_INVITED = 'Connection invited',

  CONTACT_ASSIGNED = 'Contact assigned',

  ORDER_SEND_MESSAGE = 'Order send message',
  ORDER_LINE_SEND_MESSAGE = 'Order line send message',
  SHIPMENT_SEND_MESSAGE = 'Shipment send message',
  ORDER_OPEN = 'Order open',
  ORDER_LINE_OPEN = 'Order line open',
  ORDER_LINE_ITEM_DETAILS_OPEN = 'Order line item details dialog open',

  ORDER_LINE_LOGISTICAL_UPDATED = 'Order line logistics updated',

  ORDER_BULK_SUPPLIER_CONFIRM = 'Order supplier bulk confirm',
  ORDER_BULK_SUPPLIER_PROPOSE = 'Order supplier bulk proposal',
  ORDER_BULK_SUPPLIER_REJECT = 'Order supplier bulk reject',

  SHIPMENT_REJECT = 'Shipment reject',
  SHIPMENT_APPROVE = 'Shipment approve',

  ORDER_LINE_ATTACH_DOCUMENT = 'Order line attach document',
  ORDER_ATTACH_DOCUMENT = 'Order attach document',

  ORDER_LINE_REQUEST_REOPEN = 'Order line request reopen',
  ORDER_BULK_SUPPLIER_REOPEN = 'Order supplier bulk reopen',

  ORDER_UPDATE_LABELS = 'Order update labels',
  ORDER_LINE_UPDATE_LABELS = 'Order line update labels',

  ORDER_REMOVE_LABELS = 'Order remove labels',
  ORDER_LINE_REMOVE_LABELS = 'Order line remove labels',

  ORDER_LINE_SUPPLIER_APPROVE_REQUEST_REOPEN = 'Order line supplier approve reopen request',
  ORDER_LINE_BUYER_APPROVE_REQUEST_REOPEN = 'Order line buyer approve request reopen',

  ORDER_LINE_SUPPLIER_REJECT_REQUEST_REOPEN = 'Order line supplier reject reopen request',
  ORDER_LINE_BUYER_REJECT_REQUEST_REOPEN = 'Order line buyer reject reopen request',

  ORDER_BUYER_APPROVE_CHANGES_PROPOSAL = 'Order buyer approve changes proposal',
  ORDER_BUYER_REJECT_CHANGES_PROPOSAL = 'Order buyer reject changes proposal',
  ORDER_SUPPLIER_CONFIRM_ORDER_LINE = 'Order supplier confirm order line',
  ORDER_SUPPLIER_REJECT_ORDER_LINE = 'Order supplier reject order line',
  ORDER_SUPPLIER_PROPOSE_CHANGES_TO_ORDER_LINE = 'Order supplier propose changes to order line',
  ORDER_FILTER_SEARCH_USAGE = 'Order buyer/supplier search',
  ORDER_OPEN_FROM_ACTIVITY = 'Order open from activity',
  ORDER_LINE_OPEN_FROM_ACTIVITY = 'Order line open from activity',

  SHIPMENT_OPEN_FROM_ACTIVITY = 'Shipment open from activity',
  USER_SHIPMENT_OPEN_FROM_ACTIVITY = 'User shipment open from activity',
  COMPANY_SHIPMENT_OPEN_FROM_ACTIVITY = 'Company shipment open from activity',
  DASHBOARD_SHIPMENT_OPEN_FROM_ACTIVITY = 'Dashboard shipment open from activity',

  TASK_BUYER_APPROVE_CHANGES_PROPOSAL = 'Task buyer approve changes proposal',
  TASK_BUYER_REJECT_CHANGES_PROPOSAL = 'Task buyer reject changes proposal',
  TASK_BUYER_REJECT_REQUEST_REOPEN = 'Task buyer reject request reopen',
  TASK_SUPPLIER_REJECT_REQUEST_REOPEN = 'Task supplier reject request reopen',
  TASK_SUPPLIER_APPROVE_REQUEST_REOPEN = 'Task supplier approve request reopen',
  TASK_BUYER_APPROVE_REQUEST_REOPEN = 'Task buyer approve request reopen',
  TASK_SUPPLIER_CONFIRM_ORDER_LINE = 'Task supplier confirm order line',
  TASK_SUPPLIER_REJECT_ORDER_LINE = 'Task supplier reject order line',
  TASK_SUPPLIER_PROPOSE_CHANGES_TO_ORDER_LINE = 'Task Supplier propose changes to order line',
  TASK_CLOSE = 'Task close',
  TASK_SEND_MESSAGE = 'Task send message',
  TASK_ORDER_LINE_OPEN = 'Task order line open',
  TASK_ORDER_OPEN = 'Task order open',
  TASK_FORECAST_OPEN = 'Task forecast open',
  TASK_FILTER_SEARCH_USAGE = 'Task buyer/supplier search',
  USER_ORDER_OPEN_FROM_ACTIVITY = 'User order open from activity',
  USER_ORDER_LINE_OPEN_FROM_ACTIVITY = 'User order line open from activity',
  COMPANY_ORDER_OPEN_FROM_ACTIVITY = 'Company order open from activity',
  COMPANY_ORDER_LINE_OPEN_FROM_ACTIVITY = 'Company order line open from activity',
  DASHBOARD_ORDER_OPEN_FROM_ACTIVITY = 'Dashboard order open from activity',
  DASHBOARD_ORDER_LINE_OPEN_FROM_ACTIVITY = 'Dashboard order line open from activity',
  SHIPMENT_OPEN = 'Shipment open',
  SHIPMENT_LINE_OPEN = 'Shipment line open',
  SHIPMENT_FILTER_SEARCH_USAGE = 'Shipment buyer/supplier search',
  EMPTY = 'Empty event for mockup component',

  EXPORT_PAGE_TO_CSV = 'Export one page to CSV',
  EXPORT_ALL_DATA_TO_CSV = 'Export all data to CSV',
  GENERATION_CSV_FAILED = 'Generate CSV report - Failed',
  GENERATION_CSV_SUCCESS = 'Generate CSV report - Success',
  GENERATION_XLSX_SUCCESS = 'Generate XLSX report - Success',
  REQUESTS_CSV_DATA_FAILED = 'Requests and map fields to get data for generation CSV failed',
  GENERATION_XLSX_FAILED = 'Requests and map fields to get data for generation XLSX failed',
  BULK_LOGISTICAL_STATUS_UPDATE = 'Bulk logistical status update',

  REDIRECT_TO_USER_LOCALE = 'Redirect to user locale',

  ORDER_TYPE_CHOSEN_PURCHASE = 'Orders page orderType Purchase orders',
  ORDER_TYPE_CHOSEN_FORECAST = 'Orders page orderType Forecast orders',
  ORDER_TYPE_CHOSEN_RFQ = 'Orders page orderType RFQ orders',

  CHECK_USER_COUNTRY_FAILED = 'Check user country failed',
  DIRECT_DOWNLOAD_DOCUMENT = 'Direct download document',

  TASK_STATUS_OPEN = 'Task page filter taskStatus Created/Open',
  TASK_STATUS_CLOSED = 'Task page filter taskStatus Closed',

  TASKS_VIEW_COMPANY = 'Task page filter View Tasks MyCompany',
  TASKS_VIEW_BUYERS = 'Task page filter View Tasks MyBuyers',
  TASKS_VIEW_SUPPLIERS = 'Task page filter View Tasks MySuppliers',
  TASKS_VIEW_ALL = 'Task page filter View Tasks All',

  TASK_CONTACT = 'Task page filter Contact',

  TASK_MODULE_PURCHASE = 'Task page filter module Purchase',
  TASK_MODULE_FORECAST = 'Task page filter module Forecast',
  TASK_MODULE_SHIPMENTS = 'Task page filter module Shipments',
  TASK_MODULE_RFQ = 'Task page filter module RFQ',

  TASK_CATEGORY_CONVERSATIONS = 'Task page filter category Conversations',
  TASK_CATEGORY_EXCEPTIONS = 'Task page filter category Exceptions',
  TASK_CATEGORY_NOTIFICATIONS = 'Task page filter category Notifications',
  TASK_TYPE_CHOSEN_FORECAST = 'Orders page orderType Forecast orders',

  SHIPMENT_ATTACH_DOCUMENT = 'Shipment attach document',

  ORDER_NEW_VIEW = 'Toggle to new view',
  ORDER_OLD_VIEW = 'Toggle to old view',
}
