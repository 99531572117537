import { AfterContentInit, ChangeDetectionStrategy, Component, Host } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';

@Component({
  selector: 'tc-mat-form-field-clear',
  templateUrl: './mat-form-field-clear.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatFormFieldClearComponent implements AfterContentInit {
  control!: NgControl;

  constructor(@Host() private matFormField: MatFormField) {}

  ngAfterContentInit(): void {
    this.control = this.matFormField._control.ngControl!;
  }
}
