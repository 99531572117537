import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Money, OrderLine, Prices } from '@app/order/models';
import { hasOpenBuyerReopenRequest, hasOpenSupplierReopenRequest } from '@app/order/util/helper';
import { priceDifference } from '@app/order/shared/components/price-difference/helpers/price-difference.helper';

@Component({
  selector: 'tc-line-pricing',
  templateUrl: './line-pricing.component.html',
  styleUrls: ['./line-pricing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinePricingComponent {
  @Input()
  orderLine?: OrderLine;

  public get prices(): Prices | undefined {
    if (hasOpenBuyerReopenRequest(this.orderLine!) && this.orderLine!.buyerLine.requests!.reopenRequest!.prices) {
      return this.orderLine!.buyerLine.requests!.reopenRequest!.prices;
    }

    if (hasOpenSupplierReopenRequest(this.orderLine!) && this.orderLine!.supplierLine.requests.reopenRequest!.prices) {
      return this.orderLine!.supplierLine.requests.reopenRequest!.prices;
    }

    return (
      this.orderLine?.confirmedLine?.prices ||
      (this.isProposed() && this.orderLine!.supplierLine.requests.proposal!.prices) ||
      this.orderLine?.buyerLine.prices
    );
  }

  public get amountView(): Money | undefined {
    return this.orderLine?.totalAmountIncludingRequests;
  }

  public get amountChanged(): boolean | undefined {
    return (
      this.orderLine?.totalAmountIncludingRequests?.value !== this.orderLine?.totalAmount?.value ||
      this.orderLine?.totalAmountIncludingRequests?.currencyIso !== this.orderLine?.totalAmount?.currencyIso
    );
  }

  public get contractNumber(): string | undefined {
    return this.orderLine?.buyerLine.terms?.contractNumber;
  }

  public get contractPosition(): string | undefined {
    return this.orderLine?.buyerLine.terms?.contractPosition;
  }

  private get persistedPrices(): Prices | undefined {
    return this.orderLine?.confirmedLine?.prices || this.orderLine?.buyerLine.prices;
  }

  private get requestPrices(): Prices | undefined {
    if (this.orderLine) {
      if (hasOpenBuyerReopenRequest(this.orderLine) && this.orderLine.buyerLine.requests!.reopenRequest!.prices) {
        return this.orderLine.buyerLine.requests!.reopenRequest!.prices;
      }

      if (hasOpenSupplierReopenRequest(this.orderLine) && this.orderLine.supplierLine.requests.reopenRequest!.prices) {
        return this.orderLine.supplierLine.requests.reopenRequest!.prices;
      }
      if (
        this.orderLine.supplierLine.requests?.proposal?.status === 'Open' &&
        this.orderLine.supplierLine.requests.proposal.prices
      ) {
        return this.orderLine.supplierLine.requests.proposal.prices;
      }
    }
  }

  private hasGrossPriceTransactionCurrencyChanged(): boolean {
    return (
      !!this.persistedPrices?.grossPrice?.priceInTransactionCurrency &&
      !!this.requestPrices?.grossPrice?.priceInTransactionCurrency &&
      this.persistedPrices.grossPrice.priceInTransactionCurrency.currencyIso !==
        this.requestPrices.grossPrice.priceInTransactionCurrency.currencyIso
    );
  }

  public get grossPriceChanged(): boolean {
    return (
      this.hasGrossPriceTransactionCurrencyChanged() ||
      (this.pricePerItemChanged &&
        !!this.persistedPrices?.grossPrice &&
        !!priceDifference(this.persistedPrices.grossPrice, this.requestPrices?.grossPrice))
    );
  }

  public get priceUnitChanged(): boolean | undefined {
    return (
      this.requestPrices &&
      this.persistedPrices &&
      (this.requestPrices.priceUnitOfMeasureIso !== this.persistedPrices.priceUnitOfMeasureIso ||
        (this.pricePerItemChanged && this.requestPrices.priceUnitQuantity !== this.persistedPrices.priceUnitQuantity))
    );
  }

  public get netPriceChanged(): boolean | undefined {
    return this.hasNetPriceTransactionCurrencyChanged() || this.pricePerItemChanged;
  }

  public get discountPercentageChanged(): boolean | undefined {
    return (
      this.requestPrices &&
      this.persistedPrices &&
      this.persistedPrices.discountPercentage !== this.requestPrices.discountPercentage
    );
  }

  private hasNetPriceTransactionCurrencyChanged(): boolean {
    return (
      !!this.persistedPrices?.netPrice?.priceInTransactionCurrency &&
      !!this.requestPrices?.netPrice?.priceInTransactionCurrency &&
      this.persistedPrices.netPrice.priceInTransactionCurrency.currencyIso !==
        this.requestPrices.netPrice.priceInTransactionCurrency.currencyIso
    );
  }

  private get pricePerItemChanged(): boolean {
    return (
      !!this.persistedPrices?.netPrice &&
      !!this.requestPrices?.netPrice &&
      this.persistedPrices.netPrice.priceInTransactionCurrency.value / this.persistedPrices.priceUnitQuantity !==
        this.requestPrices.netPrice.priceInTransactionCurrency.value / this.requestPrices.priceUnitQuantity
    );
  }

  private isProposed = () => {
    if (!this.orderLine || !this.orderLine.supplierLine.requests.proposal) {
      return false;
    }

    return this.orderLine.supplierLine.requests.proposal.status === 'Open';
  };
}
