import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { AnalyticsService } from './services/analytics.service';
import { reducer } from './state/analytics.reducer';
import { analyticsFeatureName } from './state/analytics.selectors';

@NgModule({
  imports: [StoreModule.forFeature(analyticsFeatureName, reducer)],
  providers: [AnalyticsService],
})
export class AnalyticsCoreModule {}
