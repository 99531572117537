import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatSelectionListChange } from '@angular/material/list';

export interface OrderedColumn {
  title: string;
  columnDef: string;
  show: boolean;
}

@Component({
  selector: 'tc-table-columns',
  templateUrl: './table-columns.component.html',
  styleUrls: ['./table-columns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableColumnsComponent {
  @Input()
  public summary = $localize`:@@ts.table.columns:Customize table`;

  @Input()
  public canSelectAll = false;

  @Input()
  public showExpand = true;

  @Input()
  set columns(value: OrderedColumn[]) {
    this.orderedColumns = value;
    this.updateSortableModel();
    this.internalSelectionModel = this.orderedColumns
      .filter(col => {
        return col.show;
      })
      .map(col => {
        return col.columnDef;
      });
    this.checked = this.internalSelectionModel.length === this.orderedColumns.length;
  }

  @Output()
  readonly columnsChange = new EventEmitter<OrderedColumn[]>();

  public orderedColumns!: OrderedColumn[];
  public internalSelectionModel: string[] = [];
  public sortableModel!: FormArray;

  public checked = false;

  public trackByFn(index: number, item: OrderedColumn): string {
    return item.columnDef;
  }

  public toggle(selected: boolean): void {
    if (selected) {
      this.selectAll();
    } else {
      this.deselectAll();
    }
  }

  public selectAll(): void {
    const columnDefs = this.orderedColumns.map(({ columnDef }) => {
      return columnDef;
    });
    this.internalSelectionModel = columnDefs;

    const val = this.orderedColumns.map(col => {
      return {
        ...col,
        show: this.internalSelectionModel.includes(col.columnDef),
      };
    });
    this.checked = true;
    this.columnsChange.emit(val);
  }

  public deselectAll(): void {
    this.internalSelectionModel = [];

    const val = this.orderedColumns.map(col => {
      return {
        ...col,
        show: false,
      };
    });

    this.checked = false;
    this.columnsChange.emit(val);
  }

  public changeSelectionModel(data: MatSelectionListChange): void {
    const changeableElement: string = data.option.value;

    if (this.internalSelectionModel.includes(changeableElement)) {
      this.internalSelectionModel.splice(
        this.internalSelectionModel.findIndex(name => {
          return name === changeableElement;
        }),
        1,
      );
    } else {
      this.internalSelectionModel.push(changeableElement);
    }

    this.checked = this.internalSelectionModel.length === this.orderedColumns.length;

    const val = this.orderedColumns.map(col => {
      return {
        ...col,
        show: this.internalSelectionModel.includes(col.columnDef),
      };
    });

    this.columnsChange.emit(val);
  }

  private updateSortableModel(): void {
    const value = this.orderedColumns.map(col => {
      return new FormControl(col);
    });
    this.sortableModel = new FormArray(value);

    this.sortableModel.valueChanges
      .pipe(
        first<OrderedColumn[]>(cols => {
          return cols.length === this.orderedColumns.length;
        }),
      )
      .subscribe(val => {
        this.columnsChange.emit(val);
      });
  }
}
