import { SeriesSplineOptions } from 'highcharts';
import { DataPoint, polynomial } from 'regression';
import { RegressionOptions } from '@app/analytics/models';

export const getRegressionSeries = (
  data: { x: number; y: number }[],
  { color, name = 'Regression', pointFormat = '{series.name}: <b>{point.y}</b>' }: RegressionOptions = {},
): SeriesSplineOptions => {
  return {
    color,
    data: polynomial(
      data.map(
        ({ x, y }): DataPoint => {
          return [x, y];
        },
      ),
      {
        precision: 50,
      },
    ).points.slice(),
    id: `regression_${Date.now()}`,
    name,
    tooltip: {
      pointFormat,
      valueDecimals: 2,
      valueSuffix: ' ',
      xDateFormat: '%Y-%m-%d',
    },
    type: 'spline',
  };
};
