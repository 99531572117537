<div fxLayout class="vertical">
  <mat-list fxFlex="50" class="mat-list--properties mat-list--compact">

    <mat-list-item *ngIf="deliverySchedule.length > 1" class="auto-height">
      <div class="property" i18n="@@order.line.details.ordered.quantity">Ordered quantity</div>
      <div class="value wrap">
        <span
          [ngClass]="{'tc-change': deliveryQuantityChanged}"
        >
          {{ deliverySchedule | tcTotalQuantity | number }}
          {{ item?.purchaseUnitOfMeasureIso | tcUnitSymbol }}
        </span>
      </div>
    </mat-list-item>
  </mat-list>
</div>
<mat-table class="compact-table" [dataSource]="displayedMerged" *ngIf="displayedMerged && displayedMerged.length > 0">
  <ng-container matColumnDef="position">
    <mat-header-cell *matHeaderCellDef i18n="as in the position of an order line in an order @@common.position">Position</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.position}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="date">
    <mat-header-cell *matHeaderCellDef i18n="@@common.date">Date</mat-header-cell>
    <mat-cell *matCellDef="let element; let i = index;">
      <span class="delivery-date" [ngClass]="{changeLine: hasDeliveryDateChanged(i)}"  [class.overdue]="element?.indicators?.deliveryOverdue">{{element.date | date:'dd/MM/yyyy'}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="quantity">
    <mat-header-cell *matHeaderCellDef i18n="@@common.quantity">Quantity</mat-header-cell>
    <mat-cell *matCellDef="let element; let i = index;">
          <span>
            <span [ngClass]="{changeLine: hasDeliveryQuantityChanged(i)}">{{element.quantity | number}}</span>
            {{ item?.purchaseUnitOfMeasureIso | tcUnitSymbol }}
          </span>
    </mat-cell>

  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef i18n="@@common.status">Status</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <tc-logistics-status [status]="element.status"></tc-logistics-status>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="transport">
    <mat-header-cell *matHeaderCellDef i18n="@@common.transport">Mode of transport</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element?.transportMode }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="etd">
    <mat-header-cell
      *matHeaderCellDef
      i18n="@@common.etd"
      matTooltip="Estimated Time of Departure"
      i18n-matTooltip="@@tooltip.etd"
    >ETD</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{element.etd | date:'dd/MM/yyyy'}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="eta">
    <mat-header-cell
      *matHeaderCellDef
      i18n="@@common.eta"
      matTooltip="Estimated Time of Arrival"
      i18n-matTooltip="@@tooltip.eta"
    >ETA</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{element.eta | date:'dd/MM/yyyy'}}
    </mat-cell>
  </ng-container>
  <mat-header-row class="compact-row" *matHeaderRowDef="columnDefs"></mat-header-row>
  <mat-row class="compact" *matRowDef="let row; columns: columnDefs; let i = index;" [ngClass]="{changeLine: isDeliveryDecrease || isDeliveryNew(i)}"></mat-row>
</mat-table>

