import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormComponent } from '@app/shared/abstracts/form.component';
import { TwoFACode } from '@app/auth/core/state/auth.model';
import { Configure2FA } from '@app/auth/core/state/auth.reducer';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { multipleLengthValidator } from '@app/util/validators';
import {
  LENGTH_BACKUP_CODE,
  LENGTH_VERIFICATION_CODE,
} from '@app/auth/routes/login/components/verify-2fa-code/verify-2fa-code-form.component';

@Component({
  selector: 'tc-disable-2fa',
  templateUrl: './disable-2fa.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Disable2faComponent extends FormComponent<TwoFACode> implements OnChanges {
  @Input()
  configure2FA!: Configure2FA;

  public form = new FormGroup({
    code: new FormControl(
      '',
      Validators.compose([Validators.required, multipleLengthValidator(LENGTH_VERIFICATION_CODE, LENGTH_BACKUP_CODE)]),
    ),
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.configure2FA && changes.configure2FA.currentValue !== changes.configure2FA.previousValue) {
      const errorCode = this.configure2FA.disable2FAErrorCode;
      if (errorCode) {
        const error =
          errorCode === 'identity.2fa.invalid_code'
            ? { [errorCode]: true }
            : { ['identity.2fa.validation_error']: true };
        this.form.controls.code.setErrors(error);
      }
    }
  }
}
