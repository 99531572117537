import { createAction, props } from '@ngrx/store';
import { Company, EndpointCredentials, OverdueSettings, UpdateCompany, WorkflowSettings } from '@app/company/models';
import { SCIConnector } from '@app/company/core/services/sci-connector.service';
import {
  ShipmentIntegrationSettings,
  UpdateShipmentIntegrationSettings,
} from '@app/company/core/services/shipment-connector.service';
import { TaskOverdueSettings } from '@app/company/routes/settings/components/task-reminder/task-reminder.model';
import { OverdueDelay } from '@app/company/routes/settings/components/task-overdue/task-overdue-delay.model';
import {
  UpdateDocumentIntegrationSettingsView,
  UpdateOrderIntegrationSettingsView,
} from '@app/company/core/services/order-webhook.service';

export enum CompanyActionType {
  FETCH = '[Company] Fetch',
  FETCH_FAILURE = '[Company] Fetch Failure',
  LISTEN = '[Company] Listen',
  UPSERT = '[Company] Upsert',
  ADD_MANY = '[Company] Add Many',
  UPDATE = '[Company] Update',
  UPDATE_NAME = '[Company] Update name',
  UPDATE_SUCCESS = '[Company] Update Success',
  UPDATE_FAILURE = '[Company] Update Failure',
  ENFORCE_2FA = '[Company] Enforce 2FA',
  UNRESTRICT_2FA = '[Company] Unrestrict 2FA',

  FETCH_SHIPMENT_CONNECTOR_SETTINGS = '[Company] Fetch Shipment Webhook Integration settings',
  FETCH_SHIPMENT_CONNECTOR_SETTINGS_SUCCESS = '[Company] Fetch Shipment Webhook Integration settings Success',

  UPSERT_SHIPMENT_CONNECTOR_SETTINGS = '[Company] Upsert Shipment Webhook Integration settings',
  UPSERT_SHIPMENT_CONNECTOR_SETTINGS_SUCCESS = '[Company] Upsert Shipment Webhook Integration settings Success',
  UPSERT_SHIPMENT_CONNECTOR_SETTINGS_FAILURE = '[Company] Upsert Shipment Webhook Integration settings Failure',

  DELETE_SHIPMENT_CONNECTOR_SETTINGS = '[Company] Delete Shipment Webhook Integration settings',

  FETCH_SCI_SETTINGS = '[Company] Fetch SCI settings',
  FETCH_SCI_SETTINGS_SUCCESS = '[Company] Fetch SCI settings Success',

  UPSERT_SCI_SETTINGS = '[Company] Upsert SCI settings',
  UPSERT_SCI_SETTINGS_SUCCESS = '[Company] Upsert SCI settings Success',
  DELETE_SCI_SETTINGS = '[Company] Delete SCI settings',

  DELETE_SCI_CREDENTIALS = '[Company] Delete supplier credentials',

  ADD_SCI_CREDENTIALS = '[Company] Add sci supplier credentials',
  ADD_SCI_CREDENTIALS_SUCCESS = '[Company] Add sci supplier credentials Success',
  ADD_SCI_CREDENTIALS_FAILURE = '[Company] Add sci supplier credentials Failure',

  FETCH_WORKFLOW_SETTINGS = '[Company] Fetch workflow settings',
  UPDATE_WORKFLOW_SETTINGS = '[Company] Update workflow settings',
  UPSERT_WORKFLOW_SETTINGS = '[Company] Upsert workflow settings',

  ENABLE_ACTING_AS_SUPPLIER = '[Company] Enable act as Supplier',
  DISABLE_ACTING_AS_SUPPLIER = '[Company] Disable act as Supplier',

  UPDATE_WORKFLOW_SETTINGS_OVERDUE = '[Company] Update workflow overdue settings',

  FETCH_ORDER_OVERDUE_SETTINGS = '[Company] Fetch Order Company Overdue settings',
  FETCH_ORDER_OVERDUE_SETTINGS_SUCCESS = '[Company] Fetch Order Company Overdue settings Success',
  FETCH_ORDER_OVERDUE_SETTINGS_FAILURE = '[Company] Fetch Order Company Overdue settings Failure',

  UPDATE_ORDER_OVERDUE_SETTINGS = '[Company] Update Order Company Overdue settings',
  UPDATE_ORDER_OVERDUE_SETTINGS_SUCCESS = '[Company] Update Order Company Overdue settings Success',

  FETCH_WEBHOOK_SETTINGS = '[Company] Fetch Webhook Company settings',
  FETCH_WEBHOOK_SETTINGS_SUCCESS = '[Company] Fetch Webhook Company settings Success',
  FETCH_WEBHOOK_SETTINGS_FAILURE = '[Company] Fetch Webhook Company settings Failure',

  UPDATE_WEBHOOK_ORDER_SETTINGS = '[Company] Update Webhook Order Company Overdue settings',
  UPDATE_WEBHOOK_ORDER_SETTINGS_SUCCESS = '[Company] Update Webhook  Order Company settings Success',
  DELETE_WEBHOOK_ORDER_SETTINGS = '[Company] Delete Webhook Order settings',
  DOWNLOAD_WEBHOOK_ORDER_CERTIFICATE = '[Company] Download Webhook Order Certificate',
  DOWNLOAD_WEBHOOK_DOCUMENT_CERTIFICATE = '[Company] Download Webhook Document Certificate',

  UPDATE_WEBHOOK_DOCUMENTS_SETTINGS = '[Company] Update Webhook Document Company Overdue settings',
  UPDATE_WEBHOOK_DOCUMENTS_SETTINGS_SUCCESS = '[Company] Update Webhook Document Company settings Success',
  DELETE_WEBHOOK_DOCUMENTS_SETTINGS = '[Company] Delete Webhook Document settings',
}

export const companyFetch = createAction(
  CompanyActionType.FETCH,
  props<{ id: string; flush: boolean; workflow?: boolean }>(),
);
export const companyFetchFailure = createAction(
  CompanyActionType.FETCH_FAILURE,
  props<{ id: string; error: string }>(),
);
export const companyListen = createAction(CompanyActionType.LISTEN, props<{ companyId: string }>());
export const companyUpsert = createAction(CompanyActionType.UPSERT, props<{ entity: Company }>());
export const companyAddMany = createAction(CompanyActionType.ADD_MANY, props<{ entities: Company[] }>());
export const companyUpdate = createAction(CompanyActionType.UPDATE, props<{ id: string; changes: UpdateCompany }>());
export const companyNameUpdate = createAction(
  CompanyActionType.UPDATE_NAME,
  props<{ id: string; changes: Pick<Company, 'name'> }>(),
);
export const companyUpdateSuccess = createAction(CompanyActionType.UPDATE_SUCCESS);
export const companyUpdateFailure = createAction(
  CompanyActionType.UPDATE_FAILURE,
  props<{ id: string; error: string }>(),
);

export const overdueSettingsFetch = createAction(
  CompanyActionType.FETCH_ORDER_OVERDUE_SETTINGS,
  props<{ id: string }>(),
);
export const overdueSettingsFetchSuccess = createAction(
  CompanyActionType.FETCH_ORDER_OVERDUE_SETTINGS_SUCCESS,
  props<{ id: string; changes: OverdueDelay }>(),
);
export const overdueSettingsFetchFailure = createAction(
  CompanyActionType.FETCH_ORDER_OVERDUE_SETTINGS_FAILURE,
  props<{ id: string }>(),
);

export const overdueSettingsUpdate = createAction(
  CompanyActionType.UPDATE_ORDER_OVERDUE_SETTINGS,
  props<{ id: string; changes: OverdueDelay }>(),
);
export const overdueSettingsUpdateSuccess = createAction(
  CompanyActionType.UPDATE_ORDER_OVERDUE_SETTINGS_SUCCESS,
  props<{ id: string; changes: OverdueDelay }>(),
);

export const enforce2FA = createAction(CompanyActionType.ENFORCE_2FA, props<{ id: string }>());
export const unrestrict2FA = createAction(CompanyActionType.UNRESTRICT_2FA, props<{ id: string }>());
export const workflowSettingsFetch = createAction(CompanyActionType.FETCH_WORKFLOW_SETTINGS, props<{ id: string }>());
export const workflowSettingsUpdate = createAction(
  CompanyActionType.UPDATE_WORKFLOW_SETTINGS,
  props<{ id: string; changes: WorkflowSettings }>(),
);
export const workflowSettingsUpsert = createAction(
  CompanyActionType.UPSERT_WORKFLOW_SETTINGS,
  props<{ id: string; changes: WorkflowSettings & OverdueSettings }>(),
);

export const workflowSettingsOverdueUpdate = createAction(
  CompanyActionType.UPDATE_WORKFLOW_SETTINGS_OVERDUE,
  props<{ id: string; changes: TaskOverdueSettings }>(),
);

export const shipmentIntegrationSettingsFetch = createAction(
  CompanyActionType.FETCH_SHIPMENT_CONNECTOR_SETTINGS,
  props<{ id: string }>(),
);
export const shipmentIntegrationSettingsFetchSuccess = createAction(
  CompanyActionType.FETCH_SHIPMENT_CONNECTOR_SETTINGS_SUCCESS,
  props<{ id: string; changes: ShipmentIntegrationSettings }>(),
);
export const shipmentIntegrationSettingsUpsert = createAction(
  CompanyActionType.UPSERT_SHIPMENT_CONNECTOR_SETTINGS,
  props<{
    id: string;
    body: UpdateShipmentIntegrationSettings;
  }>(),
);
export const shipmentIntegrationSettingsUpsertSuccess = createAction(
  CompanyActionType.UPSERT_SHIPMENT_CONNECTOR_SETTINGS_SUCCESS,
);
export const shipmentIntegrationSettingsUpsertFailure = createAction(
  CompanyActionType.UPSERT_SHIPMENT_CONNECTOR_SETTINGS_FAILURE,
  props<{
    id: string;
    error: string;
  }>(),
);
export const shipmentIntegrationSettingsDelete = createAction(
  CompanyActionType.DELETE_SHIPMENT_CONNECTOR_SETTINGS,
  props<{ id: string }>(),
);

export const sciSettingsFetch = createAction(CompanyActionType.FETCH_SCI_SETTINGS, props<{ id: string }>());
export const sciSettingsFetchSuccess = createAction(
  CompanyActionType.FETCH_SCI_SETTINGS_SUCCESS,
  props<{ id: string; changes: SCIConnector }>(),
);

export const deleteSciCredentials = createAction(
  CompanyActionType.DELETE_SCI_CREDENTIALS,
  props<{
    companyId: string;
    supplierCompanyId: string;
  }>(),
);

export const addSciCredentials = createAction(
  CompanyActionType.ADD_SCI_CREDENTIALS,
  props<{
    id: string;
    supplierCompanyId: string;
    body: EndpointCredentials;
  }>(),
);

export const upsertSciCompanyUrl = createAction(
  CompanyActionType.UPSERT_SCI_SETTINGS,
  props<{
    id: string;
    body: { url: string };
  }>(),
);

export const deleteSciCompanyUrl = createAction(
  CompanyActionType.DELETE_SCI_SETTINGS,
  props<{
    id: string;
  }>(),
);

export const upsertSciCompanyUrlSuccess = createAction(
  CompanyActionType.UPSERT_SCI_SETTINGS_SUCCESS,
  props<{
    id: string;
    changes: { url: string };
  }>(),
);

export const addSciCredentialsSuccess = createAction(CompanyActionType.ADD_SCI_CREDENTIALS_SUCCESS);
export const addSciCredentialsFailure = createAction(
  CompanyActionType.ADD_SCI_CREDENTIALS_FAILURE,
  props<{ id: string; supplierCompanyId: string; error: string }>(),
);

export const enableActSupplier = createAction(CompanyActionType.ENABLE_ACTING_AS_SUPPLIER, props<{ id: string }>());
export const disableActSupplier = createAction(CompanyActionType.DISABLE_ACTING_AS_SUPPLIER, props<{ id: string }>());

export const fetchWebhookConnector = createAction(
  CompanyActionType.FETCH_WEBHOOK_SETTINGS,
  props<{
    id: string;
  }>(),
);

export const fetchWebhookConnectorSuccess = createAction(
  CompanyActionType.FETCH_WEBHOOK_SETTINGS_SUCCESS,
  props<{
    id: string;
    changes: any;
  }>(),
);

export const deleteOrderWebhookConnector = createAction(
  CompanyActionType.DELETE_WEBHOOK_ORDER_SETTINGS,
  props<{
    id: string;
  }>(),
);

export const deleteDocumentsWebhookConnector = createAction(
  CompanyActionType.DELETE_WEBHOOK_DOCUMENTS_SETTINGS,
  props<{
    id: string;
  }>(),
);

export const documentsWebhookConnectorUpdate = createAction(
  CompanyActionType.UPDATE_WEBHOOK_DOCUMENTS_SETTINGS,
  props<{ id: string; body: UpdateDocumentIntegrationSettingsView }>(),
);

export const documentsWebhookConnectorUpdateSuccess = createAction(
  CompanyActionType.UPDATE_WEBHOOK_DOCUMENTS_SETTINGS_SUCCESS,
  props<{ id: string; changes: UpdateDocumentIntegrationSettingsView }>(),
);

export const ordersWebhookConnectorUpdate = createAction(
  CompanyActionType.UPDATE_WEBHOOK_ORDER_SETTINGS,
  props<{ id: string; body: UpdateOrderIntegrationSettingsView }>(),
);
export const ordersWebhookConnectorUpdateSuccess = createAction(
  CompanyActionType.UPDATE_WEBHOOK_ORDER_SETTINGS_SUCCESS,
  props<{ id: string; changes: UpdateOrderIntegrationSettingsView }>(),
);
export const documentsWebhookDownloadCertificate = createAction(
  CompanyActionType.DOWNLOAD_WEBHOOK_DOCUMENT_CERTIFICATE,
  props<{ id: string; }>(),
);

export const ordersWebhookDownloadCertificate = createAction(
  CompanyActionType.DOWNLOAD_WEBHOOK_ORDER_CERTIFICATE,
  props<{ id: string; }>(),
);
