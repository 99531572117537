import { AccountInfo, AuthenticationResult, Configuration, PublicClientApplication } from '@azure/msal-browser';
import { from, Observable, of, throwError } from 'rxjs';
import { StorageKeys, StorageService } from '@app/core/services/storage/storage.service';
import { azureAppMappings, ssoConnections } from '@app/auth/routes/login/state/sso.connections';
import { Inject, Injectable } from '@angular/core';
import { ROLLBAR } from '@app/core/services/error-tracking/rollbar.factory';
import * as Rollbar from 'rollbar';

@Injectable({ providedIn: 'root' })
export class MsalService {
  private myMSALObj: PublicClientApplication | undefined;

  constructor(
    @Inject(ROLLBAR) private rollbar: Rollbar,
    private storage: StorageService
  ) {}

  handleRedirectPromise$(): Observable<AuthenticationResult | null> {
    if (!this.getMSALInstance()) {
      return throwError(new Error('MSAL instance not initialized'));
    }

    return from(this.getMSALInstance().handleRedirectPromise());
  }

  loginRedirect$(): Observable<void> {
    if (!this.getMSALInstance()) {
      return throwError(new Error('MSAL instance not initialized'));
    }

    return from(this.getMSALInstance().loginRedirect({ scopes: ['openid', 'profile', 'email', 'offline_access'] }));
  }

  acquireToken$(): Observable<AuthenticationResult> {
    if (!this.getMSALInstance()) {
      return throwError(new Error('MSAL instance not initialized'));
    }

    const accountInfo = this.storage.get<AccountInfo>(StorageKeys.MSAL_ACCOUNT);
    if (!accountInfo) {
      return throwError(new Error('Account not present'));
    }
    const request = {
      scopes: ['openid', 'profile', 'email', 'offline_access'],
      account: accountInfo,
    };

    return from(this.getMSALInstance().acquireTokenSilent(request));
  }

  logout$(): Observable<any> {
    this.clearMSALHistory();

    return of({});
  }

  getMSALInstance(): PublicClientApplication {
    this.rollbar.info('getMSALInstance called ');
    if (!this.myMSALObj) {
      const email = this.storage.get(StorageKeys.MSAL_EMAIL) as string;
      this.rollbar.info('email ', email);
      if (email) {
        const connection = ssoConnections.getConnection(email);
        this.rollbar.info('connection', connection);
        if (connection) {
          const azureAppMapping = azureAppMappings[connection];
          this.rollbar.info('azureAppMapping', azureAppMapping);
          if (azureAppMapping) {
            const config: Configuration = {
              auth: {
                clientId: azureAppMapping.clientId,
                authority: `https://login.microsoftonline.com/${azureAppMapping.tenantId}/`,
                redirectUri: `${window.location.origin}/msal-callback/login`,
                navigateToLoginRequestUrl: false,
              },
              cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false,
              },
            };
            this.myMSALObj = new PublicClientApplication(config);
          }
        }
      }
    }
    this.rollbar.info('return myMSALObj');
    return this.myMSALObj!;
  }

  clearMSALHistory(): void {
    const keys = Object.keys(sessionStorage);
    keys.forEach(key => {
      if (key.startsWith('msal.')) {
        sessionStorage.removeItem(key);
      }
    });
  }
}
