<mat-toolbar fxLayoutAlign="start stretch">
  <a routerLink="/" class="button-logo" mat-button fxHide.lt-lg>
    <tc-company-logo [company]="company" size="64"></tc-company-logo>
  </a>

  <button mat-button class="button-menu" fxHide.gt-md (click)="toggleSidenav.emit()">
    <mat-icon>menu</mat-icon>
  </button>

  <div fxFlex>
    <tc-search></tc-search>
  </div>

  <div fxLayout fxHide.lt-lg>

    <a mat-button routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" i18n="@@core.navigation.links.1">Dashboard</a>

    <a mat-button routerLink="/analytics" *tcFeatureToggle="'false'" routerLinkActive="active" i18n="@@core.navigation.links.2">Metrics</a>

    <a mat-button routerLink="/workflow" routerLinkActive="active" i18n="@@core.navigation.links.3">Tasks</a>

    <a mat-button routerLink="/forecasts" *tcFeatureToggle="'showMockModules'" routerLinkActive="active" i18n="@@core.navigation.links.5">Forecasts</a>

    <a mat-button routerLink="/orders" routerLinkActive="active" i18n="@@core.navigation.links.6">Orders</a>

    <a mat-button routerLink="/shipments" *tcCompanyLimitation="ids" routerLinkActive="active" i18n="@@core.navigation.links.7">Shipments</a>

    <a
      [tcTrackClick]="{ eventName: mixpanelPageViewEvents.KNOWLEDGE_BASE }"
      mat-button
      href="https://www.tradecloud1.com/en/knowledge-base/"
      target="_blank"
    >
      <mat-icon>help</mat-icon>
    </a>

  </div>

  <button mat-button class="button-user" [class.button-user--highlight]="actingAsCompany" [matMenuTriggerFor]="userMenu">
    <tc-profile-picture [user]="user"></tc-profile-picture>
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
</mat-toolbar>

<mat-menu #userMenu="matMenu" [overlapTrigger]="false">
  <ng-container *ngIf="actingAsCompany">
    <a mat-menu-item (click)="endActingAsCompany.emit()">
      <span i18n="As in stop acting as a buyer @@core.navigation.links.8">Stop acting as </span>
      <tc-company-name [company]="company"></tc-company-name>
    </a>
    <mat-divider></mat-divider>
  </ng-container>
  <a mat-menu-item [routerLink]="['/user', userId]" i18n="@@core.navigation.links.9">My profile</a>
  <a mat-menu-item [routerLink]="['/company', companyId]" i18n="@@core.navigation.links.10">My network</a>
  <a mat-menu-item [routerLink]="['/company', companyId, 'team']" i18n="@@core.navigation.links.11">My team</a>
  <a mat-menu-item [routerLink]="['/company', companyId, 'settings']" *tcRole="['admin', 'super_user', 'support']"
    i18n="@@core.navigation.links.settings">My company</a>
  <mat-divider></mat-divider>
  <a mat-menu-item routerLink="/super-user" *tcRole="['super_user', 'support']" i18n="@@core.navigation.links.support">Support</a>
  <button mat-menu-item (click)="logout.emit()" i18n="@@core.navigation.links.13">Log out</button>
</mat-menu>
