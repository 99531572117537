import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AttachDocumentEntityType } from '@app/order/private/components/attach-document-dialog/interfaces/enums/attach-document-entity-type.enum';

export interface RemoveLabelInputDataInterface {
  entityType: AttachDocumentEntityType.ORDER | AttachDocumentEntityType.ORDER_LINE;
  entityId: string;
  text: string;
}

@Component({
  selector: 'tc-remove-label-dialog',
  templateUrl: './remove-label-dialog.component.html',
  styleUrls: ['./remove-label-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoveLabelDialogComponent {
  public form = new FormGroup({
    reason: new FormControl(null),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RemoveLabelInputDataInterface,
    private dialogRef: MatDialogRef<RemoveLabelInputDataInterface, { reason: string }>,
  ) {}

  submit() {
    const { reason } = this.form.value;

    this.dialogRef.close({
      reason,
    });
  }
}
