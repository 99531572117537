import { createAction, props } from '@ngrx/store';
import {
  OrderApprove,
  OrderProposal,
  OrderRejection,
  OrderReopen,
  OrderResend,
  OrderUpdateLogistics,
  StartOrderAcceptance,
  StartOrderProposal,
  StartOrderRejection,
  StartOrderReopen,
  StartOrderUpdateLogistical,
  StartRemoveOrderLabel,
  StartUpdateOrderLabels,
} from '@app/order/models';
import { CompanyRole } from '@app/company/models';

export enum OrderDetailActionType {
  START_ACCEPT = '[Order Detail] Start Accept',
  ACCEPT = '[Order Detail] Accept',
  ACCEPT_SUCCESS = '[Order Detail] Accept Success',
  ACCEPT_FAILURE = '[Order Detail] Accept Failure',

  START_UPDATE_LABELS = '[Order Detail] Start Update Labels',
  UPDATE_LABELS = '[Order Detail] Update Labels',

  REMOVE_LABEL = '[Order Detail] Remove Label',

  START_PROPOSE = '[Order Detail] Start Propose',
  PROPOSE = '[Order Detail] Propose',
  PROPOSE_SUCCESS = '[Order Detail] Propose Success',
  PROPOSE_FAILURE = '[Order Detail] Propose Failure',

  START_REOPEN = '[Order Detail] Start Reopen',
  REOPEN = '[Order Detail] Reopen',
  REOPEN_SUCCESS = '[Order Detail] Reopen Success',
  REOPEN_FAILURE = '[Order Detail] Reopen Failure',

  START_REJECT = '[Order Detail] Start Reject',
  START_REJECT_BUYER_REQUEST = '[Order Detail] Start Reject Buyer Request',
  REJECT_BUYER_REQUEST = '[Order Detail] Reject Buyer Request',
  REJECT = '[Order Detail] Reject',
  REJECT_SUCCESS = '[Order Detail] Reject Success',
  REJECT_FAILURE = '[Order Detail] Reject Failure',

  APPROVE = '[Order Detail] Approve',
  APPROVE_SUCCESS = '[Order Detail] Approve Success',
  APPROVE_FAILURE = '[Order Detail] Approve Failure',

  RESEND = '[Order Detail] Resend',
  RESEND_SUCCESS = '[Order Detail] Resend Success',
  RESEND_FAILURE = '[Order Detail] Resend Failure',

  CONFLICTED = '[Order Detail] Conflicted',
  UPDATE_CONFLICTED_ORDER = '[Order Detail] Update Conflicted Order',

  START_BULK_UPDATE_LOGISTIC = '[Order Detail] Start Bulk Update Logistic',
  BULK_UPDATE_LOGISTIC = '[Order Detail] Bulk Update Logistic',
  BULK_UPDATE_LOGISTIC_SUCCESS = '[Order Detail] Bulk Update Logistic Success',
  START_BULK_UPDATE_LOGISTIC_FAILURE = '[Order Detail] Bulk Update Logistic Failure',
}

export const orderDetailConflicted = createAction(OrderDetailActionType.CONFLICTED);

export const orderDetailUpdateConflicted = createAction(OrderDetailActionType.UPDATE_CONFLICTED_ORDER);

export const orderDetailStartAccept = createAction(OrderDetailActionType.START_ACCEPT, props<StartOrderAcceptance>());
export const orderDetailAccept = createAction(OrderDetailActionType.ACCEPT, props<StartOrderAcceptance>());
export const orderDetailAcceptSuccess = createAction(OrderDetailActionType.ACCEPT_SUCCESS);
export const orderDetailAcceptFailure = createAction(
  OrderDetailActionType.ACCEPT_FAILURE,
  props<{ error: any; status: number }>(),
);

export const orderDetailStartReject = createAction(OrderDetailActionType.START_REJECT, props<StartOrderRejection>());
export const orderDetailStartRejectBuyerOrSupplierRequest = createAction(
  OrderDetailActionType.START_REJECT_BUYER_REQUEST,
  props<StartOrderRejection & { role: CompanyRole }>(),
);
export const orderDetailReject = createAction(OrderDetailActionType.REJECT, props<OrderRejection>());
export const orderDetailRejectBuyerRequest = createAction(
  OrderDetailActionType.REJECT_BUYER_REQUEST,
  props<OrderRejection>(),
);
export const orderDetailRejectSuccess = createAction(OrderDetailActionType.REJECT_SUCCESS);
export const orderDetailRejectFailure = createAction(
  OrderDetailActionType.REJECT_FAILURE,
  props<{ error: any; status: number }>(),
);

export const orderDetailStartPropose = createAction(
  OrderDetailActionType.START_PROPOSE,
  props<{ startProposal: StartOrderProposal }>(),
);
export const orderDetailPropose = createAction(OrderDetailActionType.PROPOSE, props<{ proposal: OrderProposal }>());
export const orderDetailProposeSuccess = createAction(OrderDetailActionType.PROPOSE_SUCCESS);
export const orderDetailProposeFailure = createAction(
  OrderDetailActionType.PROPOSE_FAILURE,
  props<{ error: any; status: number }>(),
);

export const orderDetailApprove = createAction(OrderDetailActionType.APPROVE, props<OrderApprove>());
export const orderDetailApproveSuccess = createAction(OrderDetailActionType.APPROVE_SUCCESS);
export const orderDetailApproveFailure = createAction(
  OrderDetailActionType.APPROVE_FAILURE,
  props<{ error: any; status: number }>(),
);

export const orderDetailResend = createAction(OrderDetailActionType.RESEND, props<OrderResend>());
export const orderDetailResendSuccess = createAction(OrderDetailActionType.RESEND_SUCCESS);
export const orderDetailResendFailure = createAction(
  OrderDetailActionType.RESEND_FAILURE,
  props<{ error: any; status: number }>(),
);

export const orderDetailStartReopen = createAction(
  OrderDetailActionType.START_REOPEN,
  props<{ startReopen: StartOrderReopen }>(),
);
export const orderDetailReopen = createAction(OrderDetailActionType.REOPEN, props<{ request: OrderReopen }>());
export const orderDetailReopenSuccess = createAction(OrderDetailActionType.REOPEN_SUCCESS);
export const orderDetailReopenFailure = createAction(
  OrderDetailActionType.REOPEN_FAILURE,
  props<{ error: any; status: number }>(),
);

export const orderDetailStartBulkUpdateLogistics = createAction(
  OrderDetailActionType.START_BULK_UPDATE_LOGISTIC,
  props<StartOrderUpdateLogistical>(),
);
export const orderDetailBulkUpdateLogistics = createAction(
  OrderDetailActionType.BULK_UPDATE_LOGISTIC,
  props<OrderUpdateLogistics>(),
);
export const orderDetailBulkUpdateLogisticsSuccess = createAction(OrderDetailActionType.BULK_UPDATE_LOGISTIC_SUCCESS);
export const orderDetailBulkUpdateLogisticsFailure = createAction(
  OrderDetailActionType.START_BULK_UPDATE_LOGISTIC_FAILURE,
  props<{ error: any; status: number }>(),
);
export const orderDetailStartUpdateLabels = createAction(
  OrderDetailActionType.START_UPDATE_LABELS,
  props<StartUpdateOrderLabels>(),
);
export const orderDetailUpdateLabels = createAction(
  OrderDetailActionType.UPDATE_LABELS,
  props<StartUpdateOrderLabels>(),
);
export const orderDetailRemoveLabel = createAction(OrderDetailActionType.REMOVE_LABEL, props<StartRemoveOrderLabel>());
