<tc-activity
  *ngFor="let activity of activities; trackBy: trackByFn"
  [user]="activity.meta.source.userId | tcLazyUser | async"
  [timestamp]="activity.createdAt"
  [companyId]="activity.meta.source.companyId"
  [internal]="activity.activityType === ActivityType.ConversationInternalMessageAdded"
>
  <ng-container tc-activity-picture [ngSwitch]="activity.activityType">
    <div class="error" *ngSwitchCase="ActivityType.OrderResponseMessageSentToBuyer">!</div>
    <div class="error" *ngSwitchCase="ActivityType.OrderLineResponseMessageSentToBuyer">!</div>
    <div class="icon" *ngSwitchCase="ActivityType.OrderLineDeliveryOverdue">
      <mat-icon>hourglass_empty</mat-icon>
    </div>
    <tc-profile-picture *ngSwitchDefault [user]="activity.meta.source.userId | tcLazyUser | async" [showRole]="true"></tc-profile-picture>
  </ng-container>

  <ng-container tc-activity-user-company [ngSwitch]="activity.activityType">
    <ng-container *ngSwitchCase="ActivityType.OrderResponseMessageSentToBuyer"></ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderLineResponseMessageSentToBuyer"></ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderLineDeliveryOverdue"></ng-container>
    <ng-container *ngSwitchDefault>
      <span *ngIf="activity.meta.source.userId">
        <tc-user-name [user]="activity.meta.source.userId | tcLazyUser | async" class="text-attention"></tc-user-name>
        <ng-container i18n="As in user x from company y did the following.. @@activity.from">
          from
        </ng-container>
      </span>
      <ng-container *ngIf="activity.meta.source.companyId | tcLazyCompany | async as company">
        <tc-company-name [company]="company" class="text-attention"></tc-company-name>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container tc-activity-title [ngSwitch]="activity.activityType">
    <ng-container *ngSwitchCase="ActivityType.OrderLineDeliveryOverdue">
      <ng-container i18n="@@activity.list.line.overdue">
        Order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink, context: {$implicit: endText}"></ng-container>
      <ng-template #endText i18n="@@activity.list.line.overdue.end">
        has been labelled overdue.
      </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderLineShipmentApprovedByBuyer">
      <ng-container i18n="@@activity.list.line.ship.app">
        approved shipment
      </ng-container>
      <ng-container *ngTemplateOutlet="shipmentLink"></ng-container>
      <ng-container i18n="@@activity.list.line.ship.app.middle">
        which includes delivery line(s) of order line
      </ng-container>
      <a
        [tcTrackClick]="{ eventName: mixpanelOrderLineClickEvent }"
        [routerLink]="['/orders', activity.content.line.orderId, 'line', activity.content.line.id]"
      >
        {{activity.content.line?.purchaseOrderNumber}}-{{activity.content.line?.buyerLine?.position}}
      </a>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.ConversationInternalMessageAdded">
      <ng-container i18n="As in user X added a note to order Y @@activity.list.internal.add">
        added a note to
      </ng-container>
      <ng-container [ngSwitch]="activity.content.conversation.conversationId.entityType">
        <a *ngSwitchCase="'order'" [routerLink]="['/orders', activity.content.conversation.conversationId.entityId]">
          <ng-container *ngIf="activity.content.conversation.conversationId.entityId | tcLazyOrder | async as order">
            <tc-order-name [order]="order"></tc-order-name>
          </ng-container>
        </a>
        <ng-container *ngIf="activity.content.conversation.conversationId.entityId | tcLazyOrderLine | async as orderLine">
          <a *ngSwitchCase="'line'"
             [routerLink]="orderLine | tcOrderLineRouterLink">
            <tc-order-line-name
              [orderLine]="orderLine"
            ></tc-order-line-name>
          </a>
        </ng-container>
      </ng-container>
      <p>
        "{{ activity.content.conversation.content }}"
      </p>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.ConversationMessageAdded">
      <ng-container i18n="As in user X sent a message related to order Y @@activity.list.add">
        sent a message related to
      </ng-container>
      <ng-container [ngSwitch]="activity.content.conversation.conversationId.entityType">
        <a *ngSwitchCase="'order'" [routerLink]="['/orders', activity.content.conversation.conversationId.entityId]">
          <ng-container *ngIf="activity.content.conversation.conversationId.entityId | tcLazyOrder | async as order">
            <tc-order-name [order]="order"></tc-order-name>
          </ng-container>
        </a>
        <a
          *ngSwitchCase="'shipment'"
          [tcTrackClick]="{ eventName: mixpanelShipmentClickEvent }"
          [routerLink]="['/shipments/detail', activity.content.conversation.conversationId.entityId]"
        >
          <ng-container *ngIf="activity.content.conversation.conversationId.entityId | tcLazyShipment | async as shipment">
            {{shipment.supplierShipment.shipmentNumber}}
          </ng-container>
        </a>
        <ng-container
          *ngSwitchCase="'line'"
        >
          <ng-container *ngIf="activity.content.conversation.conversationId.entityId | tcLazyOrderLine | async as orderLine">
            <a [routerLink]="orderLine | tcOrderLineRouterLink">
              <tc-order-line-name
                [orderLine]="orderLine"
              ></tc-order-line-name>
            </a>
          </ng-container>
        </ng-container>
      </ng-container>
      <p>
        "{{ activity.content.conversation.content }}"
      </p>
    </ng-container>
    <ng-container *ngSwitchCase="[ActivityType.OrderResentByBuyer, ActivityType.OrderResentBySupplier].includes(activity.activityType) ? activity.activityType : !activity.activityType">
      <ng-container i18n="as in resend the order message agian @@activity.list.resent">
        resend order
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLink"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderResponseMessageSentToBuyer">
      <ng-container i18n="As in An error has occurred related to order #123 @@activity.list.error.response">
        An error has occurred related to
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLink"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderLineResponseMessageSentToBuyer">
      <ng-container i18n="@@activity.list.error.response">
        An error has occurred related to
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="[ActivityType.OrderLineResentByBuyer, ActivityType.OrderLineResentBySupplier].includes(activity.activityType) ? activity.activityType : !activity.activityType">
      <ng-container i18n="as in resend the order line message agian @@activity.list.resent.line">
        resend  order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderIssuedByBuyer">
      <ng-container i18n="As in the sentence User X issued order #123 @@activity.list.issued">
        issued order
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLink"></ng-container>
    </ng-container>
    <ng-container
      *ngSwitchCase="[ActivityType.OrderUpdatedByBuyer, ActivityType.OrderUpdatedBySupplier].includes(activity.activityType) ? activity.activityType : !activity.activityType"
    >
      <ng-container i18n="As in the sentence User X updated order #123 @@activity.list.updated">
        updated order
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLink"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderLinesReissuedByBuyer">
      <ng-container i18n="As in the sentence User X reissued order #123 @@activity.list.reissued">
        reissued order
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLink"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderReopenedByBuyer">
      <ng-container i18n="As in the sentence User X reopened order #123 @@activity.list.reopened">
        reopened order
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLink"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ActivityType.OrderLineShipmentIssuedBySupplier">
      <ng-container i18n="@@activity.list.ship.issued">
        issued shipment
      </ng-container>
      <ng-container *ngTemplateOutlet="shipmentLink"></ng-container>
      <ng-container i18n="@@activity.list.ship.common.end">
        which includes delivery line(s) of order line
      </ng-container>
      <ng-container *ngTemplateOutlet="shOrderLineLink"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ActivityType.OrderLineShipmentReissuedBySupplier">
      <ng-container i18n="@@activity.list.ship.reissued">
        reissued shipment
      </ng-container>
      <ng-container *ngTemplateOutlet="shipmentLink"></ng-container>
      <ng-container i18n="@@activity.list.ship.common.end">
        which includes delivery line(s) of order line
      </ng-container>
      <ng-container *ngTemplateOutlet="shOrderLineLink"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ActivityType.OrderLineShipmentRejectedByBuyer">
      <ng-container i18n="@@activity.list.ship.rejected">
        rejected shipment
      </ng-container>
      <a
        [tcTrackClick]="{ eventName: mixpanelShipmentClickEvent }"
        [routerLink]="['/shipments/detail', activity.content?.shipment.id]"
      >
        {{ activity.content?.shipment.supplierShipment.shipmentNumber }}
      </a>
      <ng-container i18n="@@activity.list.ship.common.end">
        which includes delivery line(s) of order line
      </ng-container>
      <ng-container *ngTemplateOutlet="shOrderLineLink"></ng-container>
      <ng-container *ngIf="activity.content.shipment.message">
        <p>
          <span>Reason</span>: "{{ activity.content.shipment?.message }}"
        </p>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ActivityType.ShipmentDespatchedBySupplier">
      <ng-container i18n="@@activity.list.ship.despatch">
        despatched Shipment
      </ng-container>
      <ng-container *ngTemplateOutlet="shipmentLink"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ActivityType.ShipmentResentByBuyer">
      <ng-container i18n="@@activity.list.ship.resend">
        resend shipment
      </ng-container>
      <ng-container *ngTemplateOutlet="shipmentLink"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ActivityType.ShipmentIssueBySupplier">
      <ng-container i18n="@@activity.list.ship.issued">
        issued shipment
      </ng-container>
      <ng-container *ngTemplateOutlet="shipmentLink"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ActivityType.ShipmentReissueBySupplier">
      <ng-container i18n="@@activity.list.ship.reissued">
        reissued shipment
      </ng-container>
      <ng-container *ngTemplateOutlet="shipmentLink"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ActivityType.ShipmentApprovedByBuyer">
      <ng-container i18n="@@activity.list.ship.approved">
        approved shipment
      </ng-container>
      <ng-container *ngTemplateOutlet="shipmentLink"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ActivityType.ShipmentRejectedByBuyer">
      <ng-container i18n="@@activity.list.ship.rejected">
        rejected shipment
      </ng-container>
      <ng-container *ngTemplateOutlet="shipmentLink"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ActivityType.OrderLineDeliveryHistoryAppendedByBuyer">
      <ng-container i18n="As in the sentence User X booked a goods receipt on order line #123 @@activity.list.delivery.appended">
        booked a goods receipt on order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
    </ng-container>
    <ng-container
      *ngSwitchCase="[ActivityType.OrderLineDeliveryScheduleLogisticsUpdatedByBuyer, ActivityType.OrderLineDeliveryScheduleLogisticsUpdatedBySupplier].includes(activity.activityType) ? activity.activityType : !activity.activityType"
    >
      <ng-container i18n="As in the sentence User X updated the logistical status of order line #123 @@activity.list.updated.logistic">
        updated the logistical status of order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
    </ng-container>
    <ng-container
      *ngSwitchCase="[ActivityType.OrderMarkedOpenBySupplier, ActivityType.OrderMarkedOpenByBuyer].includes(activity.activityType) ? activity.activityType : !activity.activityType"
    >
      <ng-container i18n="As in the sentence User X marked order #123 as delivered @@activity.list.marked">
        marked order
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLink"></ng-container>
      <ng-container i18n="As in the sentence User X marked order #123 as not delivered @@activity.list.not.delivered">
        as not delivered
      </ng-container>
    </ng-container>

    <ng-container
      *ngSwitchCase="[ActivityType.OrderMarkedDeliveredByBuyer, ActivityType.OrderMarkedDeliveredBySupplier].includes(activity.activityType) ? activity.activityType : !activity.activityType"
    >
      <ng-container i18n="As in the sentence User X marked order #123 as delivered @@activity.list.marked">
        marked order
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLink"></ng-container>
      <ng-container i18n="Words in the sentence: user x from company y marked order line ### As delivered @@activity.list.delivered">
        as delivered
      </ng-container>
    </ng-container>


    <ng-container
      *ngSwitchCase="ActivityType.OrderLineConfirmedBySupplier"
    >
      <ng-container i18n="As in User X confirmed order line #123 @@activity.list.line.confirmed">
        confirmed order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink, context: {$implicit: endText}"></ng-container>
      <ng-template #endText i18n="As in user X confirmed order Y with other values then requested or agreed upon. @@activity.list.confirmed.end">
        with other values then requested or agreed upon.
      </ng-template>
    </ng-container>

    <ng-container
      *ngSwitchCase="ActivityType.OrderLineConfirmedByBuyer"
    >
      <ng-container i18n="As in User X confirmed order line #123 @@activity.list.line.buyer.confirmed">
        unilaterally confirmed order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ActivityType.OrderLinesAcceptedBySupplier">
      <ng-container i18n="As in the sentence User X confirmed multiple lines of order #123 @@activity.list.confirmed">
        confirmed multiple lines of order
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLink"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderLinesRejectedBySupplier">
      <ng-container i18n="As in the sentence User X rejected multiple lines of order #123 @@activity.list.rejected">
        rejected multiple lines of order
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLink"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderChangesProposedBySupplier">
      <ng-container i18n="As in the sentence User X proposed changes for multiple lines of order #123 @@activity.list.proposed">
        proposed changes for multiple lines of order
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLink"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderChangesProposalApprovedByBuyer">
      <ng-container i18n="As in the sentence User X approved the changes to order line #123 @@activity.list.approved">
        approved the changes to order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderChangesProposalRejectedByBuyer">
      <ng-container i18n="As in the sentence User X rejected the changes to order line #123 @@activity.list.rejected">
        rejected the changes to order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderLineCompletedByBuyer">
      <ng-container i18n="As in the sentence User X marked order line #123 as delivered @@activity.list.marked.line">
        marked order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink, context: {$implicit: paramTemplate}">
      </ng-container>
      <ng-template #paramTemplate i18n="As in the sentence User X marked order #123 as completed @@activity.list.completed">
        as completed
      </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderCompletedByBuyer">
      <ng-container i18n="As in the sentence User X marked order #123 as delivered @@activity.list.marked">
        marked order
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLink"></ng-container>
      <ng-container i18n="As in the sentence User X marked order #123 as completed @@activity.list.completed">
        as completed
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ActivityType.OrderLineIssuedByBuyer">
      <ng-container i18n="Words in the sentence: user x from company y issued order line #123 @@activity.list.issued.line">
        issued order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
    </ng-container>
    <ng-container
      *ngSwitchCase="[ActivityType.OrderLineUpdatedByBuyer, ActivityType.OrderLineUpdatedBySupplier].includes(activity.activityType) ? activity.activityType : !activity.activityType"
    >
      <ng-container i18n="As in the sentence User X updated order line #123 @@activity.list.updated.line">
        updated order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
    </ng-container>

    <ng-container
      *ngSwitchCase="[ActivityType.OrderLineMarkedOpenByBuyer, ActivityType.OrderLineMarkedOpenBySupplier].includes(activity.activityType) ? activity.activityType : !activity.activityType"
    >
      <ng-container i18n="As in the sentence User X marked order line #123 as delivered @@activity.list.marked.line">
        marked order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink, context: {$implicit: notDelivered}">
      </ng-container>
      <ng-template #notDelivered i18n="As in the sentence User X marked order #123 as not delivered @@activity.list.not.delivered">
        as not delivered
      </ng-template>
    </ng-container>

    <ng-container
      *ngSwitchCase="[ActivityType.OrderLineMarkedDeliveredByBuyer, ActivityType.OrderLineMarkedDeliveredBySupplier].includes(activity.activityType) ? activity.activityType : !activity.activityType"
    >
      <ng-container i18n="As in the sentence User X marked order line #123 as delivered @@activity.list.marked.line">
        marked order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink, context: {$implicit: delivered}">
      </ng-container>
      <ng-template #delivered i18n="As in the sentence User X marked order #123 as delivered @@activity.list.delivered">
        as delivered
      </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="ActivityType.CompletedOrderLineRevertedByBuyer">
      <ng-container i18n="As in the sentence User X reopened the completed order line #123 @@activity.list.reopened.completed.line">
        reopened the completed order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ActivityType.OrderLineCancelledByBuyer">
      <ng-container i18n="As in the sentence User X cancelled order line #123 @@activity.list.cancelled.line">
        cancelled order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderLinesCancelledByBuyer">
      <ng-container i18n="As in the sentence User X cancelled multiple lines of order #123 @@activity.list.cancelled.multiple.line">
        cancelled multiple lines of order
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLink"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderSynced">
      <ng-container i18n="As in the sentence User X synced order #123 @@activity.list.synced">
        synced order
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLink"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderLineSynced">
      <ng-container i18n="As in the sentence User X synced order line #123 @@activity.list.synced.line">
        synced order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderLineReissuedByBuyer">
      <ng-container i18n="As in the sentence User X reissued order line #123 @@activity.list.reissued.line">
        reissued order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderLineReopenedByBuyer">
      <ng-container i18n="As in the sentence User X reopened order line #123 @@activity.list.reopened.line">
        reopened order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderLineAcceptedBySupplier">
      <ng-container i18n="As in the sentence User X accepted order line #123 @@activity.list.accepted.line">
        accepted order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderLineRejectedBySupplier">
      <ng-container i18n="As in the sentence User X rejected order line #123 @@activity.list.rejected.line">
        rejected order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderLineChangesProposedBySupplier">
      <ng-container i18n="As in the sentence User X proposed changes to order line #123 @@activity.list.proposed.line">
        proposed changes to order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
      <ng-container *ngIf="activity.content.line.supplierLine.requests.proposal">
        <tc-line-proposal [orderLine]="activity.content.line"></tc-line-proposal>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderLineChangesProposalApprovedByBuyer">
      <ng-container i18n="As in the sentence User X approved the changes to order line #123 @@activity.list.approved.changes.line">
        approved the changes to order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ActivityType.OrderLineChangesProposalRejectedByBuyer">
      <ng-container i18n="Words in the sentence: user x from company y rejected the changes to order line #123 @@activity.list.rejected.changes.line">
        rejected the changes to order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
    </ng-container>

    <!--start reopen activity-->
    <ng-container *ngSwitchCase="[ActivityType.OrderLineReopenBySupplier, ActivityType.OrderLineReopenByBuyer].includes(activity.activityType) ? activity.activityType : !activity.activityType">
      <ng-container i18n="As in the sentence User X requested changes for order line #123 @@activity.list.requested.changes.line">
        requested changes for order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="[ActivityType.OrderLineReopenApprovedBySupplier, ActivityType.OrderLineReopenApprovedByBuyer].includes(activity.activityType) ? activity.activityType : !activity.activityType">
      <ng-container i18n="As in the sentence User X approved the reopen request for order line #123 @@activity.list.approved.reopen.line">
        approved the reopen request for order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="[ActivityType.OrderLineReopenRejectedBySupplier, ActivityType.OrderLineReopenRejectedByBuyer].includes(activity.activityType) ? activity.activityType : !activity.activityType">
      <ng-container i18n="As in the sentence User X rejected the reopen request for order line #123 @@activity.list.rejected.reopen.line">
        rejected  the reopen request for order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
    </ng-container>
    <!--end reopen activity-->

    <!-- start order custom labels activity -->
    <ng-container *ngSwitchCase="[ActivityType.OrderCustomLabelsAddedByBuyer, ActivityType.OrderCustomLabelsAddedBySupplier].includes(activity.activityType) ? activity.activityType : !activity.activityType">
      <ng-container i18n="@@activity.list.order.labels.added">
        added the following label to order
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLink"></ng-container>
      <ng-container
        *ngTemplateOutlet="customLabels;context:{
          customLabels: (activity.activityType === ActivityType.OrderCustomLabelsAddedByBuyer ? activity.content.order.buyerOrder.customLabels: activity.content.order.supplierOrder.customLabels)
        }"
      ></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="[ActivityType.OrderCustomLabelsRemovedByBuyer, ActivityType.OrderCustomLabelsRemovedBySupplier].includes(activity.activityType) ? activity.activityType : !activity.activityType">
      <ng-container i18n="@@activity.list.order.labels.removed">
        removed the following label from order
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLink"></ng-container>
      <ng-container
        *ngTemplateOutlet="customLabels;context:{
          customLabels: (activity.activityType === ActivityType.OrderCustomLabelsRemovedByBuyer ? activity.content.order.buyerOrder.customLabels : activity.content.order.supplierOrder.customLabels)
        }"
      ></ng-container>
    </ng-container>
    <!-- end order custom labels activity -->

    <!-- start order line custom labels activity -->
    <ng-container *ngSwitchCase="[ActivityType.OrderLineCustomLabelsAddedByBuyer, ActivityType.OrderLineCustomLabelsAddedBySupplier].includes(activity.activityType) ? activity.activityType : !activity.activityType">
      <ng-container i18n="@@activity.list.line.labels.added">
        added the following label to order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
      <ng-container
        *ngTemplateOutlet="customLabels;context:{
          customLabels: (activity.activityType === ActivityType.OrderLineCustomLabelsAddedByBuyer ? activity.content.line.buyerLine.customLabels : activity.content.line.supplierLine.customLabels)
        }"
      ></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="[ActivityType.OrderLineCustomLabelsRemovedByBuyer, ActivityType.OrderLineCustomLabelsRemovedBySupplier].includes(activity.activityType) ? activity.activityType : !activity.activityType">
      <ng-container i18n="@@activity.list.line.labels.removed">
        removed the following label from order line
      </ng-container>
      <ng-container *ngTemplateOutlet="orderLineLink"></ng-container>
      <ng-container
        *ngTemplateOutlet="customLabels;context:{
          customLabels: (activity.activityType === ActivityType.OrderLineCustomLabelsRemovedByBuyer ? activity.content.line.buyerLine.customLabels : activity.content.line.supplierLine.customLabels)
        }"
      ></ng-container>
    </ng-container>
    <!-- end order line custom labels activity -->

    <ng-template #customLabels let-customLabels="customLabels">
      <div style="margin-top: 10px;">
        <tc-label-list [labels]="customLabels"></tc-label-list>
        <ng-container *ngFor="let label of customLabels">
          <p style="margin-left: 10px;" *ngIf="label.reason">
            Reason: "<span>{{ label.reason }}</span>"
          </p>
        </ng-container>
      </div>
    </ng-template>

    <tc-document-activity-list
      tc-activity-document
      [activity]="activity"
      [mixpanelOrderClickEvent]="mixpanelOrderClickEvent"
      [mixpanelOrderLineClickEvent]="mixpanelOrderLineClickEvent"
      [mixpanelShipmentClickEvent]="mixpanelShipmentClickEvent"
    ></tc-document-activity-list>
  </ng-container>

  <ng-container [ngSwitch]="activity.activityType">
    <ng-container *ngSwitchCase="ActivityType.OrderLineReopenBySupplier">
      <ng-container *ngIf="activity.content.line.supplierLine.requests.reopenRequest">
        <tc-line-reopen [orderLine]="activity.content.line"></tc-line-reopen>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ActivityType.OrderLineReopenByBuyer">
      <ng-container *ngIf="activity.content.line.confirmedLine">
        <tc-line-reopen [orderLine]="activity.content.line"></tc-line-reopen>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #shOrderLineLink>
    <a
      [tcTrackClick]="{ eventName: mixpanelOrderLineClickEvent }"
      [routerLink]="['/orders', activity.content.line.orderId, 'line', activity.content.line.id]"
    >
      {{activity.content.line?.purchaseOrderNumber}}-{{activity.content.line?.buyerLine?.position}}
    </a>
  </ng-template>

  <ng-template #shipmentLink>
    <a
      [tcTrackClick]="{ eventName: mixpanelShipmentClickEvent }"
      [routerLink]="['/shipments/detail', activity.content?.shipment.id]"
    >
      {{ activity.content?.shipment.supplierShipment.shipmentNumber }}
    </a>
    <ng-container *ngIf="activity.content.shipment.message">
      <p>
        <span>Reason</span>: "{{ activity.content.shipment?.message }}"
      </p>
    </ng-container>
  </ng-template>

  <ng-template #orderLink>
    <a
      [tcTrackClick]="{ eventName: mixpanelOrderClickEvent }"
      [routerLink]="['/orders', activity.content.order.id]"
    >
      {{ activity.content.order.buyerOrder.purchaseOrderNumber }}
    </a>
    <ng-container *ngIf="activity.content.order.message">
      <p>
        <span>Reason</span>: "{{ activity.content.order.message }}"
      </p>
    </ng-container>
  </ng-template>

  <ng-template #orderLineLink let-param>
    <ng-container *ngIf="activity.content.line.id | tcLazyOrderLine | async as orderLine">
      <a
        [tcTrackClick]="{ eventName: mixpanelOrderLineClickEvent }"
        [routerLink]="orderLine | tcOrderLineRouterLink"
      >
        <tc-order-line-name [orderLine]="orderLine"></tc-order-line-name>
      </a>
    </ng-container>

    <ng-container *ngTemplateOutlet="param"></ng-container>
    <ng-container *ngIf="activity.content.line.message">
      <p>
        <span>Reason</span>: "{{ activity.content.line.message }}"
      </p>
    </ng-container>
  </ng-template>
</tc-activity>
