import { Environment } from './environment.interface';

export const environment: Environment = {
  environment: 'prod',
  intercomAppId: 'nel1ptj2',
  production: true,
  rollbarAccessToken: 'e11c7f9a600e45ecb8a6fbe0789c1016',
  ipBaseGeoKey: 'GqQQrDQJStM7UwEphJccOuCEmj6Blx1aiwWFrUVX',
  mixpanelToken: '4be23d414e02e091a58bb04071aca77c',
  mixpanelId: '1381849',
  retryConnectionFailures: true,
  azure: {
    domainMappings: {
      'damen.com': 'damen',
      'elcee.ch': 'elcee',
      'elcee.com': 'elcee',
      'elcee.de': 'elcee',
      'elcee.dk': 'elcee',
      'elcee.nl': 'elcee',
      'elcee.se': 'elcee',
      'tradecloud1.onmicrosoft.com': 'tradecloud',
    },
    app: {
      damen: {
        clientId: 'b4bb5e03-d3a2-423f-8d91-a242304baaea',
        tenantId: 'ddc22e8a-9100-4f41-8be4-472d728426c5',
      },
      elcee: {
        clientId: '8ca631c8-3d65-4a4e-8af8-66a3dcd4347d',
        tenantId: 'e55db25b-50a2-4622-9a7f-7e7a65ec221e',
      },
      tradecloud: {
        clientId: '321fc72a-3e7b-440a-96e2-c99d34ad784c',
        tenantId: 'fc2e432f-1e78-4e28-ac62-33861e815454',
      },
    },
  },
};
