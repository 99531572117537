import { Measurement } from '@app/analytics/models';

export function getSeriesName(confirmed: boolean, regression: boolean): string {
  const name = confirmed
    ? $localize`:@@ts.chart1:Confirmed vs. Delivered`
    : $localize`:@@ts.chart2:Requested vs. Delivered`;

  const regPrefix = $localize`:@@ts.chart.reg:Regression`;

  return regression ? `${regPrefix} ${name}` : name;
}

export function normalizeMeasurement(datum: Measurement): { x: number; y: number; description: string } {
  let description = '';

  if (datum.orderLine) {
    const { purchaseOrderNumber } = datum.orderLine.buyerOrder;
    const { position } = datum.orderLine.buyerLine;

    description = `${purchaseOrderNumber} - ${position}`;
  }

  return {
    x: datum.date.getTime(),
    y: datum.value * 100,
    description,
  };
}

export function getSeriesColor(confirmed: boolean, regression: boolean): string {
  if (confirmed) {
    return regression ? '#90caf9' : '#448aff';
  }

  return regression ? '#ce93d8' : '#9c27b0';
}
