import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { filter, map, tap } from 'rxjs/operators';
import { FormControlComponent, getFormControlProviders } from '@app/shared/abstracts/form-control.component';
import { validateIsObject } from '@app/shared/components/autocomplete/helpers/validate-is-object.helper';
import { FloatLabelType } from '@angular/material/form-field';

@Component({
  selector: 'tc-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: getFormControlProviders(AutocompleteComponent),
})
export class AutocompleteComponent<T> extends FormControlComponent implements AfterViewInit {
  @Input()
  label!: string;

  @Input()
  floatLabel: FloatLabelType = 'auto';

  @Input()
  hint?: string;

  @Input()
  clear = false;

  @Input()
  loading!: boolean;

  @Input()
  options!: T[];

  @Input()
  displayWith!: (entity: T | null) => string | null;

  @Input()
  set required(required: boolean) {
    this.control.setValidators(required ? validateIsObject : null);
  }

  @ViewChild(MatAutocomplete)
  autocomplete!: MatAutocomplete;

  @ViewChild(MatAutocompleteTrigger)
  trigger!: MatAutocompleteTrigger;

  public control = new FormControl(null);

  ngAfterViewInit(): void {
    // Triggered when the autocomplete panel is closed
    this.trigger.panelClosingActions
      .pipe(
        tap(() => {
          return (this.loading = false);
        }),
        map(() => {
          return this.autocomplete.options;
        }),
        map(options => {
          // No options were available, so no value can be set.
          if (options.length === 0 || options.first.disabled) {
            return null;
          }

          // If an item was selected using the arrow keys, set its value.
          // Normally this value is discarded unless the user presses the enter key.
          const active = options.find(item => {
            return item.active;
          });
          if (active) {
            return active.value as T;
          }

          // The control's value is a string which means no item was selected yet.
          // In this case we assume the user intended to select the first option.
          if (typeof this.control.value === 'string') {
            return this.autocomplete.options.first.value as T;
          }
        }),
        // None of the above, which means an item was clicked and its value is already set.
        filter(value => {
          return value !== undefined;
        }),
      )
      .subscribe(value => {
        this.control.setValue(value);
      });
  }
}
